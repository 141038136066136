import React, {useRef, useEffect, useState} from "react";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, lineWidth, chartWidth }) => {
  const r = chartWidth/2;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={125}
      cy={75}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={lineWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage, testSize}) => {
  return (
    <text
	  className="pieText"
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={testSize}
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const Pie = ({ percentage, colour , lineWidth, testSize, chartWidth}) => {
	const pct = cleanPercentage(percentage);
	const circleRef = useRef();
	const [circleWidth, setCircleWidth] = useState([lineWidth?lineWidth:"0.3rem",testSize?testSize:"0.6em"]);
  
	useEffect(() => {
		circleRef.current && circleRef.current.addEventListener('mouseenter', () => setCircleWidth(["0.6rem","0.9rem"]));
	},[]);

	useEffect(() => {
		circleRef.current && circleRef.current.addEventListener('mouseleave', () => setCircleWidth([lineWidth?lineWidth:"0.3rem",testSize?testSize:"0.6em"]));
	},[]);
	
  return (
    <svg width={chartWidth?chartWidth:50} height={chartWidth?chartWidth:50} ref={circleRef}>
      <g transform={`rotate(-90 ${"50 100"})`}>
        <Circle colour="lightgrey" lineWidth={lineWidth?lineWidth:"0.3rem"} chartWidth={chartWidth?chartWidth-10:40} />
        <Circle colour={colour} pct={pct} lineWidth={lineWidth?lineWidth:"0.3rem"} chartWidth={chartWidth?chartWidth-10:40}/>
      </g>
      <Text percentage={pct} testSize={circleWidth[1]}/>
    </svg>
  );
};

export default Pie;
