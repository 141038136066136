import React, { memo , useRef, useEffect, useState, useLayoutEffect, forwardRef, useImperativeHandle,Children  } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import polyline from '@mapbox/polyline'; 

import Container from 'react-bootstrap/Container';
import Placeholder from 'react-bootstrap/Placeholder';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Collapse from 'react-bootstrap/Collapse';

import BsTooltip from 'react-bootstrap/Tooltip' ;
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import ReactSlider from 'react-slider'
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import MultiRangeSlider from "./component/MultiRangeSlider";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Table from 'react-bootstrap/Table';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import TooltipBs from 'react-bootstrap/Tooltip';
import FormRange from 'react-bootstrap/FormRange'
import { CookiesProvider, useCookies } from 'react-cookie'

import FormControlLabel from '@mui/material/Button';
import Checkbox from '@mui/material/ButtonGroup';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
//import Logo from './sun.png';
//import BerlinOverlay from "./ShortenedEntries2.png";
//import Collapse from 'react-bootstrap/Collapse';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
//import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
//import { createElement } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Fade from 'react-bootstrap/Fade';

import * as Icon from 'react-icons/bs';
import * as TbIcon from "react-icons/tb";
import * as FaIcon from "react-icons/fa";
import * as AiIcon from "react-icons/ai";
import * as IoIcon from "react-icons/io";
import * as MdIcon from "react-icons/md";
import * as GiIcon from "react-icons/gi";
import * as ImIcon from "react-icons/im";

import { PiTrademarkRegistered } from "react-icons/pi";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import {isolines, featureCollection, interpolate, polygonSmooth, lineToPolygon, isobands, featureEach } from '@turf/turf';

// Import Swiper styles
// import temperature-map-gl
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import "./styles.css";

// import required modules
import { Navigation, EffectCoverflow, Controller, EffectCards, Pagination, EffectCube, FreeMode, Thumbs } from "swiper";
import Pie from "./Pie";
import { TagPicker,Slider, RangeSlider } from 'rsuite';


import './App.css';
//import origRentalsGEOJson from './placesGeoJson.json';
//import origRentalsUSGEOJson from './placesUSGeoJson.json';
//import origRentalsGEOJson from './120123placesGeoJson.json';
//import origRentalsGEOJson from './placesBPGeoJsonShort.json';
import ReactEcharts from "echarts-for-react"; 
import { Chart } from "react-google-charts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Radar, Bar,  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent, } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);


{/*
			var minim=0;
			var maxim=0;
			origRentalsGEOJson.sort((a, b) => a.properties.avgPrice - b.properties.avgPrice);
			//origRentalsGEOJson.map((item) => {
			//	if 	(item.properties.avgPrice < minim) { minim = item.properties.avgPrice}
			//	if (item.properties.avgPrice > maxim) {maxim = item.properties.avgPrice}
			//});
			
			var median = origRentalsGEOJson[Math.ceil(origRentalsGEOJson.length/2)].properties.avgPrice;
			var range = origRentalsGEOJson[origRentalsGEOJson.length-1].properties.avgPrice - origRentalsGEOJson[0].properties.avgPrice;
			minim = median-range/2;
			maxim = median+range/2;

			var breaks = [];
			var incr = (maxim-minim)/(20+1);
			//breaks.map((e,i) => e=minim+i*incr);
			for (let i = 0; i < 20; i++) {breaks = [...breaks,(minim+i*incr)]}
			console.log('breaks',breaks);
			
			
			var collection = featureCollection(origRentalsGEOJson.filter((item) => item.properties.avgPrice>minim&&item.properties.avgPrice<maxim ));

			var options = {gridType: 'points', property: 'avgPrice', units: 'kilometers', weight: 1.5};
			var grid = interpolate(collection, 0.5, options);		
			
			var polygon = isobands(grid, breaks, {zProperty: 'avgPrice'});
			var smoothed;
			var allSmoothed = featureCollection([]);


			
			featureEach(polygon,(item,i) => {
				if (item.geometry.coordinates.length>0) {
					try {
						smoothed = polygonSmooth(item, {iterations: 1});
						allSmoothed.features.push(...smoothed.features);
					}
					catch(err) {
						allSmoothed.features.push(...polygon.features);
						console.log('polygonSmooth failed');
					}
				}				
			});
			polygon = allSmoothed;
			console.log('polygon',polygon);
			*/}



  const cityCoords = [
{'label':'Budapest','value': {lat: 19.06934446961646,lon: 47.49893300831466,zoom: 9}},
{'label':'Abu Dhabi','value':{lat: 53.537773, lon: 24.159202,zoom: 9}},
{'label':'Amsterdam','value':{lat: 4.909043441, lon: 52.3546295,zoom: 9}},
{'label':'Barcelona','value':{lat: 2.1487639, lon: 41.39449485,zoom: 9}},
{'label':'Bengaluru','value':{lat: 77.632586455, lon: 12.953784545,zoom: 9}},
{'label':'Berlin','value':{lat: 13.421621, lon: 52.49670375,zoom: 9}},
{'label':'Boston','value':{lat: -71.08785, lon: 42.3172,zoom: 9}},
{'label':'Chicago','value':{lat: -87.6882, lon: 41.8456,zoom: 9}},
{'label':'Copenhagen','value':{lat: 12.5483689, lon: 55.6710914,zoom: 9}},
{'label':'Dallas','value':{lat: -96.7393, lon: 32.83,zoom: 9}},
{'label':'Dubai','value':{lat: 55.2742835, lon: 25.075706,zoom: 9}},
{'label':'Dublin','value':{lat: -6.25103755, lon: 53.3238265,zoom: 9}},
{'label':'Frankfurt','value':{lat: 8.63664945, lon: 50.12004745,zoom: 9}},
{'label':'Helsinki','value':{lat: 24.9713575, lon: 60.2227145,zoom: 9}},
{'label':'Houston','value':{lat: -95.4427, lon: 29.81355,zoom: 9}},
{'label':'Lausanne','value':{lat: 0.197927293, lon: 49.932887455,zoom: 9}},
{'label':'London','value':{lat: -0.1015935, lon: 51.528715,zoom: 9}},
{'label':'Los Angeles','value':{lat: -118.4095, lon: 34.01305,zoom: 9}},
{'label':'Madrid','value':{lat: -3.6786225, lon: 40.43700305,zoom: 9}},
{'label':'Miami','value':{lat: -80.23845, lon: 25.78385,zoom: 9}},
{'label':'Milan','value':{lat: 9.1790126, lon: 45.4640367,zoom: 9}},
{'label':'Munich','value':{lat: 11.56415815, lon: 48.15429545,zoom: 9}},
{'label':'New York','value':{lat: -73.9741, lon: 40.70795,zoom: 9}},
{'label':'Noida','value':{lat: 77.39808835, lon: 28.519061335,zoom: 9}},
{'label':'Paris','value':{lat: 2.34646265, lon: 48.85886205,zoom: 9}},
{'label':'Rome','value':{lat: 7.45347265, lon: 41.71285405,zoom: 9}},
{'label':'San Francisco','value':{lat: -122.439, lon: 37.75735,zoom: 9}},
{'label':'San Jose','value':{lat: -121.887, lon: 37.3442,zoom: 9}},
{'label':'Seattle','value':{lat: -122.253, lon: 47.61795,zoom: 9}},
{'label':'Singapore','value':{lat: 103.8375675, lon: 1.3441985,zoom: 9}},
{'label':'Stockholm','value':{lat: 17.98262545, lon: 59.32610865,zoom: 9}},
{'label':'Sydney','value':{lat: 150.936218, lon: -33.847621,zoom: 9}},
{'label':'Tokyo','value':{lat: 138.36215, lon: 35.2081,zoom: 9}},
{'label':'Vienna','value':{lat: 16.3826251, lon: 48.2199279,zoom: 9}},
{'label':'Washington','value':{lat: -77.0067, lon: 38.9053,zoom: 9}},
{'label':'Zurich','value':{lat: 8.536716, lon: 47.3773622,zoom: 9}},
  ]


const App=() => {

	function isBetween (value, range) {
		const min = range[0]<range[1] ? range[0] : range[1];
		const max = min === range[1] ? range[0] : range[1];

		return value >= min && value <= max;
	}
	const [isMobile,setIsMobile]=useState(isBetween( window.innerWidth,[576,0] ) || isBetween( window.innerHeight,[576,0] ) ? true:false);
  //window.screen.orientation.type.includes('portrait') = window.innerWidth / window.innerHeight>1.75 && isMobile ? true : false;
  const [cardsPerRow,setCardsPerRow] = useState(
	  isMobile || isBetween(window.innerWidth,[576,767]) ? 1 : 
		isBetween(window.innerWidth,[767,900]) || (isBetween( window.innerHeight,[576,768]) && isBetween( window.innerWidth,[576,0] )) ? 2 : 4
		);

  const refSwiper = useRef(null);
  const rowsWindow = useRef(10);

  const rentalModal = useRef();
  const preferenceDetails = useRef({
	  destination: '',
	  adults: 0,
	  children: 0,
	  jobTitle: '',
	  industry: '',
	  sector: '',
	  professions: []
  });

	function updatePreferences (prmName, value) {
		preferenceDetails.current[prmName] = value;
		if (prmName == 'who') {
			preferenceDetails.current['adults'] = ["couple","family"].includes(value) ? 2 : 1;
			preferenceDetails.current['children'] = value == "family" ? preferenceDetails.current['children'] : 0;
			}
		//setCookie('user', preferenceDetails, { path: '/' })

		//send params to server
	}

  const rentalPlan = useRef([]);
  const jobPlan= useRef([]);	
  const schoolPlan = useRef([]);
  const scenarioList = useRef([]);
  const rjsLikedList = useRef({'Rentals': rentalPlan, 'Jobs': jobPlan, 'Schools': schoolPlan});
  
  const mapContainer = useRef(null);
  const map = useRef(null);
  const topPadding = 30;
  const rentalcardBodyRef = useRef(null);
  const BsCursorRef = useRef(null);
  
  const jobCardsBodyRef = useRef(null);
  const schoolCardsBodyRef = useRef(null);
  //const [rentalsGEOJson,setRentalsGEOJson]=useState([]);
  const rentalsGEOJson = useRef([]);
  const jobsGEOJson = useRef([]);

  const rentCardRef = useRef([]);
  const jobCardRef = useRef([]);
  const schCardRef = useRef([]);
  const visibleCard = useRef(0);
  const prevZoom = useRef(0);
  const mapNav = useRef();


  const swipePageFilter = useRef();
  const globalClustersMouseDown = useRef();
  const clustersMouseDown = useRef();
	var timer;
	const [longClick,setLongClick]=useState(false);


  //const [tooltips,setTooltips] = useState([]);

  const loadingInitData = useRef(true);
  const [error, setError] = useState(null);
  const [zoomIn, setZoomIn] = useState(true);
  //const origRentalsGEOJson = rentalsGEOJson;
  const initRentalsGEOJson = useRef([]);
  const initJobsGEOJson = useRef([]);
			//console.log('swiper swiperContainer',swiper.activeIndex)

	const filterParams = {'Rentals': ['liked','price','bed','bath','size','pets','garage','balcony'],
							'Jobs': ['liked','salary', 'industry', 'company','position']};

	const filterCategories = {
		'liked': 'check',
		'price': 'range',
		'bed': 'range',
		'bath': 'range',
		'size': 'range',
		'salary': 'range',
		'pets': 'check',
		'garage': 'check',
		'balcony': 'check',
		'position': 'list',
		'industry': 'list',
		'company': 'list'
	};

  const initLoad = () => {

    if (loadingInitData.current) {
		const fetchRentals = "https://safe2change.com/FlatPrices.php?city=&bounds=";
		console.log('fetchRentals',fetchRentals);
		
		fetch(fetchRentals,{
			mode: 'no-cors',
			credentials: 'include',
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})	
      .then(response => response.json())
      .then((usefulData) => {

			console.log('initLoad usefulData',usefulData['Rentals'],usefulData['Jobs']);


			const allRentalIDs = usefulData['Rentals']['list'].map((item) => {
				if (!item.properties.image) {item.properties.image="https://photos.renthop.com/p/c/420x300/65269292_ea4a9f320ca10494c23afea79fd417d4.jpg";}
				if (!item.properties.bed) {item.properties.bed=1;}
				if (!item.properties.bath) {item.properties.bath=1;}
					item.properties.pets = !item.properties.size;
				if (!item.properties.address) {item.properties.address ="-";}
					item.properties.imageList = item.properties.imageList.length<2 ? Array.from({ length: 2 }).map((i) => item.properties.image) : item.properties.imageList;
				return item.properties.id;

			});

			const allJobIDs = usefulData['Jobs']['list'].map((item) => {
				if (!item.properties.image) {item.properties.image="https://photos.renthop.com/p/c/420x300/65269292_ea4a9f320ca10494c23afea79fd417d4.jpg";}
				return item.properties.id;
			});

			const uniqueRentalIDs = allRentalIDs.filter((elem, pos) => allRentalIDs.indexOf(elem) == pos); 
			const uniqueRentalPoints = usefulData['Rentals']['list'].filter((elem, pos) => uniqueRentalIDs.indexOf(elem.properties.id) == pos); 

			const uniqueJobIDs = allJobIDs.filter((elem, pos) => allJobIDs.indexOf(elem) == pos); 
			const uniqueJobPoints = usefulData['Jobs']['list'].filter((elem, pos) => uniqueJobIDs.indexOf(elem.properties.id) == pos); 


			initRentalsGEOJson.current = uniqueRentalPoints;
			rentalsGEOJson.current = uniqueRentalPoints;
			initJobsGEOJson.current = uniqueJobPoints;
			jobsGEOJson.current = uniqueJobPoints;


			const widthPadding = window.innerWidth * 0.1;
			const heightPadding = window.innerHeight*0.1;
			const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: widthPadding, right: widthPadding, top: heightPadding, bottom: heightPadding} : {bottom: heightPadding, left: widthPadding, right: widthPadding, top: heightPadding};


 		if (refSwiper.current.swiper.activeIndex == 2 ) {
			const features = featureCollection(usefulData['Rentals']['overview']);
			const featureList = featureCollection(uniqueRentalPoints);
			console.log('featureList Rentals',featureList);
			map.current.fitBounds(bbox(features.features), {padding: padding, duration: 2000, easing(t) {return t;}});
			map.current.getSource('globalPlaces').setData(features);
			map.current.getSource('hiddenPlaces').setData(featureList);


		} else if (refSwiper.current.swiper.activeIndex == 3 ) {
				const features = featureCollection(usefulData['Jobs']['overview']);
				const featureList = featureCollection(uniqueJobPoints);
				console.log('featureList Jobs',featureList);
				map.current.fitBounds(bbox(features.features), {padding: padding, duration: 2000, easing(t) {return t;}});
				map.current.getSource('globalPlaces').setData(features);
				map.current.getSource('hiddenPlaces').setData(featureList);
			}
			loadingInitData.current = false;
		})
      .catch((e) => {
			console.error(`An error occurred: ${e}`);
			fetch(`/jobsUSGeoJson.json`) // add here the zoomIn data to fetch data within bounds
			  .then(response => response.json())
			  .then((usefulData) => {
				usefulData.map((item) => {
					if (!item.properties.image) {item.properties.image="https://photos.renthop.com/p/c/420x300/65269292_ea4a9f320ca10494c23afea79fd417d4.jpg";}
					});


					initJobsGEOJson.current = usefulData;
					jobsGEOJson.current = usefulData;
					if (refSwiper.current.swiper.activeIndex == 3 ) {
							const features = featureCollection(usefulData).features;
							map.current.getSource('hiddenPlaces').setData(features);
							map.current.getSource('places').setData(features);
							const widthPadding = window.innerWidth * 0.1;
							const heightPadding = window.innerHeight*0.1;
							const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: widthPadding, right: widthPadding, top: heightPadding, bottom: heightPadding} : {bottom: heightPadding, left: widthPadding, right: widthPadding, top: heightPadding};

							map.current.fitBounds(bbox(features.features), {padding: padding, duration: 2000, easing(t) {return t;}});
						}
			  })

      });	
	}	  
	  
  }


const bbox = (featureList) => {
		const lats = featureList.map(point => point.geometry.coordinates[0]);
		const lngs = featureList.map(point => point.geometry.coordinates[1]);

	return [[Math.min(...lats), Math.min(...lngs)],[Math.max(...lats), Math.max(...lngs)]];
}

//origRentalsGEOJson=origRentalsGEOJson.slice(0,40);

function amendScenarioList(scenariosToAdd) {
	console.log('scenariosToAdd start',scenariosToAdd, scenarioList.current);
	scenariosToAdd = scenariosToAdd.filter((item,i) => scenariosToAdd.indexOf(item) == i );

	scenarioList.current = scenarioList.current.filter(listItem => 
		scenariosToAdd.filter(addItem => JSON.stringify(listItem.id) == JSON.stringify(addItem.id)) < 1
	);
	scenariosToAdd = scenariosToAdd.filter((item) => item.id.filter(i => i).length > 1 );
	
	scenarioList.current = scenariosToAdd.length > 0 ? [...scenarioList.current, ...scenariosToAdd] :
		scenarioList.current;

	console.log('scenariosToAdd end',scenariosToAdd, scenarioList.current);
}


  const [lng, setLng] = useState(-15.495579042459472); //18.939119
  const [lat, setLat] = useState(45.59169348362461); //47.607043  
  const [zoom, setZoom] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  //const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const myImageStyle = { width: '100px', height: '100px', display: 'block', marginLeft: 'auto',  marginRight: 'auto' };
  const cardStyle = { width: '10rem', display: 'block', marginLeft: 'auto',  marginRight: 'auto' }
  const [horizontal, setHorizontal] = useState([0,0]);	
  const [popupShow, setPopupShow] = useState(false);	
  const [selected, setSelected] = useState([]);	
  //const [activeElement,setActiveElement] = useState(false);

  const [triggerRerendering, setTriggerRerendering]=useState(false);
  const [triggerRerender,setTriggerRerender] =useState(false);


  //var rentalCardsFilteredArray=rentalCardsArray.slice(0,20);
  const preHiddenFeaturesLen = useRef(rentalsGEOJson.current ? rentalsGEOJson.current.length:0)
  const prevUnclusteredLen = useRef(0)
  //const [popupCard, setPopupCard] = useState(false);	

  /*const getData=()=>{
    fetch('./placesGeoJson.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setOrigRentalsGEOJson(myJson)
      });
  }*/


  const ciryConturSources =  [["source0","./Budapest_ingatlancom21032022Shortened.csv.Budapest.250.png"],
					["source1","./ShortenedEntries.csv.Paris.250.png"],
					["source2","./NY_renthop.csv.New York.250.png"],
					["source3","./2022-11-18Barcelona.csv.Barcelona.250.png"],
					["source4","./2022-11-18London.csv.London.250.png"],
					]
  const dataPoints = [
		  {
			lat: 48.094903,
			lon: -1.371596,
			value: 20
		  }
	  ];
        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true
        });
const popups = useRef([]);


mapboxgl.accessToken = 'pk.eyJ1IjoiZmhhbmljaCIsImEiOiJjazRvNjE4bWIycW5vM21xdzJmcWJraWQ3In0.8GvlhVd86Uj1uyPmaVxcrA';
const navToken = 'sk.eyJ1IjoiZmhhbmljaCIsImEiOiJjbGYwYTlrem8wMDV5M3NvNWloZDF0dDc0In0.7iB8lrt_ftDiS997_skamA';


const schoolDetals = [
{
	"id": 111,
	"name": "Corvinus",
	"faculties": "high",
	"language": "english",
	"fee": 100000,
	"city": "Zurich",
	"currency": "CHF",
	"icon" : "https://www.svgrepo.com/show/162625/apple-logo.svg",
	"url": "",
	"lat": 8.536716,
	"lon": 47.3773622,
}
]



const paramTooltips = {
		  "Overall Score": "overall evaluation of the scenario",
		  "Affordability": "Costs vs income",
		  "Quality of life": "",
		  "Commute Time": "Time to go around",
		  "Security": "Safety of rental, travel and school",
		  "Location": "Prestige of the place"
	}

const [preferenceQuestions, setPreferenceQuestions] = useState([
		{
			qText: "Your dream place...",
			qType: 'search',
			aType: 'text',
			qAnswers:[
  { value: 'Budapest', label: 'Budapest' },
  { value: 'Abu Dhabi', label: 'Abu Dhabi' },
  { value: 'Amsterdam', label: 'Amsterdam' },
  { value: 'Barcelona', label: 'Barcelona' },
  { value: 'Bengaluru', label: 'Bengaluru' },
  { value: 'Berlin', label: 'Berlin' },
  { value: 'Boston', label: 'Boston' },
  { value: 'Chicago', label: 'Chicago' },
  { value: 'Copenhagen', label: 'Copenhagen' },
  { value: 'Dallas', label: 'Dallas' },
  { value: 'Dubai', label: 'Dubai' },
  { value: 'Dublin', label: 'Dublin' },
  { value: 'Frankfurt', label: 'Frankfurt' },
  { value: 'Helsinki', label: 'Helsinki' },
  { value: 'Houston', label: 'Houston' },
  { value: 'Lausanne', label: 'Lausanne' },
  { value: 'London', label: 'London' },
  { value: 'Los Angeles', label: 'Los Angeles' },
  { value: 'Madrid', label: 'Madrid' },
  { value: 'Miami', label: 'Miami' },
  { value: 'Milan', label: 'Milan' },
  { value: 'Munich', label: 'Munich' },
  { value: 'New York', label: 'New York' },
  { value: 'Noida', label: 'Noida' },
  { value: 'Paris', label: 'Paris' },
  { value: 'Rome', label: 'Rome' },
  { value: 'San Francisco', label: 'San Francisco' },
  { value: 'San Jose', label: 'San Jose' },
  { value: 'Seattle', label: 'Seattle' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Stockholm', label: 'Stockholm' },
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Tokyo', label: 'Tokyo' },
  { value: 'Vienna', label: 'Vienna' },
  { value: 'Washington', label: 'Washington' },
  { value: 'Zurich', label: 'Zurich' },

			
			],
			qAnswer: ''
		},
		{
			qText: "Your family...",
			qType: 'select',
			aType: 'text',
			qAnswers:[
  { value: 'Single', label: 'Single' },
  { value: 'Couple', label: 'Couple' },
  { value: 'Single and kids', label: 'Single and kids' },
  { value: 'Couple and kids', label: 'Couple and kids' }
			
			],			qAnswer: ''
		},
		{
			qText: "Your reason to move...",
			qType: 'select',
			aType: 'text',
			qAnswers:[/*'Career', 'Increase savings', 'Increase savings', 'Improve quality of life'*/
  { value: 'Make career', label: 'Make career' },
  { value: 'Increase savings', label: 'Increase savings' },
  { value: 'Quality life', label: 'Quality life' },
  { value: 'Schoolarship', label: 'Schoolarship' },
  { value: 'Have some rest', label: 'Have some rest' }
			],
			qAnswer: ''
		}]);
var scenarios ;

var planningState = [
	{
		questions: preferenceQuestions
	}
]

const positionMarkerContent = '<div style="width: 2em; height: 2em" class="spinner-border text-primary"></div>';
const locatorMarkerContent = '<div style="width: 2em; height: 2em" class="spinner-grow text-danger"></div>';
const positionMarker = new mapboxgl.Marker();
positionMarker.getElement().innerHTML = positionMarkerContent;
positionMarker.setOffset([0, 2]);

const locatorMarker = new mapboxgl.Marker({zIndex: '10000', color: 'red'});
locatorMarker.getElement().innerHTML = locatorMarkerContent;
locatorMarker.setOffset([0, 2]);
const cardsRef = useRef([]);
const startPos = useRef(null);
const contDiv = useRef(null);
const popCont = useRef(null);
const ref = useRef(null);
const skipNextRender = useRef(false);
const preUnclusteredFeaturesLen = useRef(null);
const preActIndex = useRef(2);
const refComparePlans = useRef(null);
const filtersSet = useRef({'Rentals': [], 'Jobs': [], 'Schools': []});
const doInit = useRef(true);
const rentalModalRef = useRef();
const isEmptyRef = useRef();
const compareModalRef = useRef();
const cityPropsPopup = useRef();
const medals = useRef();
const compareCityModal = useRef();
const spiderRef = useRef();


	const DestDropdown = () => {
		const [dest,setDest]=useState(preferenceDetails.current.destination);
		  const [cookies, setCookie] = useCookies(['user'])

				useEffect(() => {
					updatePreferences('destination', dest);
					setCookie('user', preferenceDetails.current, { path: '/' })

				},[dest]);

				function handleChange(e) {
					setDest(e);
				}
	  return (
    <CookiesProvider>
					<Select 
					  options={cityCoords}
					  placeholder="Select destinations..."
					  value={dest}
					  onChange={handleChange}
					  isSearchable={true}
					  isMulti
					/>
    </CookiesProvider>
	  );
	}

	const DestPreferences = () => {
		const [climate,setClimate]=useState(preferenceDetails.current.climate);
		const [citySize,setCitySize]=useState(preferenceDetails.current.citySize);
		const [language,setLanguage]=useState(preferenceDetails.current.language);
		const climateOptions=['Mediterran','Oceanic','Tropical','Continental','Polar', 'Arid'].map(item => ({label:item,value:item}));
		const citySizeOptions=['Big'].map(item => ({label:item,value:item}));
		const languageOptions=['English','Chineese','Spanish','Portugese','French','others'].map(item => ({label:item,value:item}));

				useEffect(() => {
					updatePreferences('climate', climate);
				
				},[climate]);

				useEffect(() => {
					updatePreferences('citySize', citySize);
				
				},[citySize]);

				useEffect(() => {
					updatePreferences('language', language);
				
				},[language]);

				function handleChange(e) {
					setClimate(e);
				}
	  return (
				<>
					<Select 
					  options={climateOptions}
					  placeholder="Select prefered climate..."
					  value={climate}
					  onChange={setClimate}
					  isSearchable={true}
					  isMulti
					/>
					<Select 
					  options={citySizeOptions}
					  placeholder="Select city size..."
					  value={citySize}
					  onChange={setCitySize}
					  isSearchable={true}
					  isMulti
					/>
					<Select 
					  options={languageOptions}
					  placeholder="Select language..."
					  value={language}
					  onChange={setLanguage}
					  isSearchable={true}
					  isMulti
					/>
				</>
	  );
	}


				const positions = [
				  { value: "account manager", label: "account manager" },
				  { value: "project manager", label: "project manager" },
				  { value: "project director", label: "project director" },
				  { value: "engineer", label: "engineer" },
				  { value: "sw developer", label: "sw developer" },
				  { value: "Scrum master", label: "Scrum master" },
				  { value: "Team lead", label: "Team lead" },
				  { value: "HR professional", label: "HR professional" },
				];

	const InitModal = () => {
		const [showInitModal,setShowInitModal]=useState(doInit.current);

		  useEffect(() => {

			if (!map.current && !showInitModal) return;

			if (preferenceDetails.current.destination.length==1) {



				map.current.flyTo({
				  animate: true,
				  zoom: preferenceDetails.current.destination[0].value.zoom,
				  tolerance: 1,
				  center: [preferenceDetails.current.destination[0].value.lat,preferenceDetails.current.destination[0].value.lon] //{lng: -82.85262744690793, lat: 41.59610735983085}
				});
				
				loadData({city: preferenceDetails.current.destination[0].label});
				

				//map.current.setFilter('globalClusters', ['in', 'city', preferenceDetails.current.destination[0].label]);
				//map.current.setFilter('globalClusters-count', ['in', 'city', preferenceDetails.current.destination[0].label]);

				//map.current.setFilter('clusters', ['in', 'city', preferenceDetails.current.destination[0].label]);
				//map.current.setFilter('cluster-count', ['in', 'city', preferenceDetails.current.destination[0].label]);
				//map.current.setFilter('unclustered-point', ['in', 'city', preferenceDetails.current.destination[0].label]);

				//map.current.setFilter('hidden-points', ['in', 'city', preferenceDetails.current.destination[0].label]);
				
			}

		  },[showInitModal]);


	const Questionare = () => {
		const [stage, setStage] = useState(0);
		const GraphicQuest = ({param, values, children}) => {
			function handleChoosen(i) {
				updatePreferences(param, values[i]);

				handleSelect();
			}
			return (
				<Row xs={children.length} md={1} className="justify-content-xs-center" style={{ margin: 'auto'}} >
					{children.map((item,i) => 
						<Col xs={12} md={4} style={{padding: '5px', margin: 'auto', textAlign: 'center'}}>
							<Button variant="outline-primary" style={{width: '100%'}} type="submit" onClick={(e) => handleChoosen(i)} >
								{item}
							</Button>
						</Col>
					)}
				</Row>
			);
		}
		
		const NextState = () => {
			return (
					<Row xs={1} md={2} className="justify-content-md-center" style={{width: '100%'}} >
						<Col xs={12} md={6} style={{padding: '5px', margin: 'auto', textAlign: 'center'}}>
							<Button variant="primary" style={{width: '100%'}} type="submit" onClick={(e) => handleSelect()} >
								<div style={{textSize: '1em'}}>
									<h5>Ok</h5>
								</div>
							</Button>
						</Col>
					</Row>
			);
		}
		
		const inputs = 
				{0:	<GraphicQuest param={'reason'} values={["compare","evaluate","explore"]}>
							<>
								<FaIcon.FaBalanceScaleLeft style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Compare places
							</>
							<>
								<GiIcon.GiOnTarget style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Evaluate target
							</>
							<>
								<MdIcon.MdOutlineTravelExplore style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Explore the world
							</>
					</GraphicQuest>,
				1:	<GraphicQuest param={'who'} values={["single","couple","family"]}>
							<>
								<ImIcon.ImMan style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Single
							</>
							<>
								<ImIcon.ImManWoman  style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Couple
							</>
							<>
								<MdIcon.MdOutlineFamilyRestroom  style={{width: '100%', height: isMobile? '30vw':'20vh', padding: isMobile? '5vw':'5vh'}} />
								Family
							</>
					</GraphicQuest>,
				2: 
				<>
					<AdultProfession who={0}/>
					{["couple","family"].includes(preferenceDetails.current.who) && <AdultProfession who={1}/>}
					{preferenceDetails.current.who == "family" && <ChildrenCount/>}
					<NextState />
				</>	
				}
		;

		function handleSelect(value) {
			setStage(stage+1);
		}

	
		useEffect(() => {
			console.log('useEffect',stage, preferenceDetails.current);
			stage == 3 && setShowInitModal(false);
		},[stage]);

		
		return (
			<>
			{inputs[stage]}
			</>
		
		);
	}

		const AddGoals = () => {
			const [goal, setGoal] = useState(preferenceDetails.current.reasonToMove);
			const goals = [
				{ label: 'Make career', value: '1' },
				{ label: 'Quality life', value: '2' },
				{ label: 'Schoolarship', value: '3' },
				{ label: 'Have some rest', value: '4' },
			];



				useEffect(() => {
					updatePreferences('reasonToMove', goal);

				},[goal]);

				function handleChange(e) {
					setGoal(e);
				}				

			const AddProfession = () => {
				const [position, setPosition] = useState(preferenceDetails.current.jobTitle);

				useEffect(() => {
					updatePreferences('jobTitle', position);

				},[position]);

				function handleChange(e) {
					setPosition(e);
				}

				return (
					<>
						<div>Which profession you want to make career in</div>
						<Select 
						  options={positions}
						  placeholder="Select profession..."
						  value={position}
						  onChange={handleChange}
						  isSearchable={true}
						  isMulti
						/>
					</>
					);
			}
			

			return (
			  <>
				<Row className="xs-4">
					<Select 
					  options={goals}
					  placeholder="Select goal..."
					  value={goal}
					  onChange={handleChange}
//					  isSearchable={true}
					  //isMulti
					aria-controls="collapse-elems"
					aria-expanded={goal?true:false}
					/>
				</Row>
				<Collapse in={goal?true:false}>
					<div id="collapse-elems">
					<Row className="xs-4" >
					{goal && {1:<AddProfession />
					}[goal.value]}
					</Row>
					</div>
			  </Collapse>


			  </>
			);
		}


			function handleClose() {
				
				if (preferenceDetails.current.adults && preferenceDetails.current.who && preferenceDetails.current.reason)
				{
					doInit.current = false;
					setShowInitModal(false);
				} else {
					alert('Please help us with some of your relocation preferences');
				}
			}

			function handleOpen(e) {
					doInit.current = true;
					setShowInitModal(true);
			}

			return (
			<>
				<div onClick={handleOpen} ><img src={"./sun-icon-png-17.jpg"} className="App-logo" height='30px'/></div>

			  <Modal show={showInitModal} 
			  onHide={handleClose}
			  size="lg"
			  centered
				dialogClassName="initModal"
			  >

				
				<Modal.Header style={{justifyContent: 'flex-start'}} closeButton>
					<span style={{fontWeight: 'bold'}}>FollowTheSun</span>
					<img src={"./sun-icon-png-17.jpg"} className="App-logo" height='30px'/>
				</Modal.Header>

				<Modal.Body style={{display: 'inline-flex',flexDirection: 'column',flexWrap: 'nowrap',alignItems: 'center'}}>
					<Questionare />

			{/*<Tab.Container defaultActiveKey="Destination" >

				  <Nav className="flex-row" fill justify style={{width: '100%'}}>
					<Nav.Item>
					  <Nav.Link className="initTab" eventKey="Destination">Destination</Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link className="initTab" eventKey="Motivation">Motivation</Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link className="initTab" eventKey="Travellers">Travellers</Nav.Link>
					</Nav.Item>
				  </Nav>
				<Tab.Content style={{padding: '10px'}}>
				<Tab.Pane eventKey="Travellers" style={{position: '', width: '100%'}}>
						<AdultCount />
						<ChildrenCount/>
				</Tab.Pane>
				<Tab.Pane eventKey="Destination" style={{position: '', width: '100%'}}>
					<Row xs={1} md={2} style={{marginBottom: '20px'}}>
						<Col className="sectionTitle">Where you want to move</Col>
						<Col className="sectionTitle" ><DestDropdown /></Col>
					</Row>
					<div style={{display: 'flex', justifyContent: 'space-around', fontSize: 'x-large', fontWeight: 'bold'}}>or</div>
					<Row xs={1} md={2} style={{marginBottom: '20px'}}>
						<Col className="sectionTitle">Let us recommend</Col>
						<Col ><DestPreferences /></Col>
					</Row>
				</Tab.Pane>
				<Tab.Pane eventKey="Motivation" style={{position: '', width: '100%'}}>
					<Row xs={1} md={2} >
						<Col className="sectionTitle">Why you want to move</Col>
						<Col ><AddGoals /></Col>
					</Row>
				</Tab.Pane>
				</Tab.Content>
			</Tab.Container>*/}
			</Modal.Body>

		{/*<Modal.Footer>
				<Container>
					<Row xs={1} md={2} className="justify-content-md-center" style={{width: '100%'}} >
						<Col xs={12} md={6} style={{padding: '5px', margin: 'auto', textAlign: 'center'}}>
							<Button variant="primary" style={{width: '100%'}} type="submit" onClick={(e) => handleClose(e)} >
								<div style={{textSize: '1em'}}>
									<h5>Follow your dream</h5>
									Select preferences
								</div>
							</Button>
						</Col>
						<Col xs={12} md={6} style={{padding: '5px', margin: 'auto', textAlign: 'center'}}>
							<Button variant="outline-primary" style={{width: '100%'}} type="submit" onClick={(e) => setShowInitModal(false)} >
								<div style={{textSize: '1em'}}>
									<h5>Explore the world</h5>
									Go without selection
								</div>
							</Button>
						</Col>
					</Row>
				</Container>
		</Modal.Footer>*/}
			  </Modal>
			</>
		);
	}


		const CheckComponent = ({quest,initValue, helpText}) => {
			const [currentValue, setCurrentValue] = useState(preferenceDetails.current[initValue]?preferenceDetails.current[initValue]:false);

			  useEffect(() => {
					updatePreferences(initValue, currentValue);
			  },[currentValue]);

			const HooverTriggerInfoTooltip = ({content}) => {
			  const renderTooltip = (props) => {
				<Tooltip id="button-tooltip" {...props}>
					{content}
				</Tooltip>
				};

			  return (
				<OverlayTrigger
				  placement="right"
				  delay={{ show: 250, hide: 400 }}
				  overlay={renderTooltip}
				>
				  <span><Icon.BsInfoCircle /></span>
				</OverlayTrigger>
			  );
			}	


			const TriggerInfoTooltip = ({content}) => {
			  const [show, setShow] = useState(false);
			  const target = useRef(null);

			  return (
				<>
				  <div type="button" className="btn" ref={target} onMouseLeave={() => setShow(false)} onMouseOver={() => setShow(true)}>
					<Icon.BsInfoCircle />
				  </div>
				  <Overlay target={target.current} show={show} placement="bottom">
					{({
					  placement: _placement,
					  arrowProps: _arrowProps,
					  show: _show,
					  popper: _popper,
					  hasDoneInitialMeasure: _hasDoneInitialMeasure,
					  ...props
					}) => (
					  <div
						{...props}
						style={{
						  position: 'absolute',
						  backgroundColor: 'rgba(0, 0, 0, 0.85)',
						  padding: '2px 10px',
						  color: 'white',
						  zIndex: 10000,
						  borderRadius: 3,
						  ...props.style,
						}}
					  >
					  {content}
					  </div>
					)}
				  </Overlay>
				</>
			  );
			}		
			
			return (
									<Row >
										<Col xs={9} style={{margin: 'auto'}}>
											{quest}<TriggerInfoTooltip content={helpText} />
										</Col>
										<Col xs={3} style={{margin: 'auto'}}>
											<Container className="">
											  <Form>
												<Form.Check
												  type="switch"
												  id="item-switch"
												  onChange={(e)=> setCurrentValue(e.currentTarget.checked)}
												  checked={currentValue}
												/>
											  </Form>
											</Container>
										</Col>
									</Row>
			);
		}

const ChildrenCount = ({user}) => {
	
	console.log('ChildrenCount',user);

	const hobbies = ['Football','Basketball','Baseball','e-sports'].map(
		  item => ({label: item, value: item}));

	const [cookies, setCookie] = useCookies(['user'])

	const [count, setCount] = useState(0);
	const [listValue, setListValue] = useState(preferenceDetails.current.kidsHobbies&& preferenceDetails.current.kidsHobbies);

	useEffect(() => {
		updatePreferences('children', count);
		setCookie('user', count, { path: '/' })


	},[count]);
	useEffect(() => {
		updatePreferences('kidsHobbies', listValue);

	},[listValue]);
	const StepInputForm = ({quest,minVal, maxVal, initVal}) => {
		console.log('StepInputForm initVal',cookies);
		
		const handleMin = (e) => {
			e.stopPropagation();
			console.log('handleMin',count, minVal);
			setCount(count - 1<minVal?minVal:count - 1);
		};

		const handlePlus = (e) => {
			e.stopPropagation();
			console.log('handlePlus',count);
			setCount(x=>x + 1>maxVal?maxVal:x + 1);
		};

		return (	 
			<>
				<Row xs={1} md={2} style={{width:"", paddingLeft:"0", marginLeft:"0px", margin: '10px'}}>
					<Col md={12} style={{textAlign: 'center', fontWeight: 'bold'}}>
						{quest}
					</Col>
					<Col md={12} style={{display: 'flex',flexWrap: 'nowrap',justifyContent: 'space-evenly'}}>
						<div className="rounded-circle stepButton" onClick={handleMin} style={{textAlign:"center", width: "1.5rem", paddingLeft:"0px"}}><Icon.BsDashCircle className="text-primary" style={{fontSize: "1.5rem", strokeWidth: '0'}} /></div>

						<div id={quest} style={{textAlign:"center", width: "1.5rem", fontWeight: 'bold'}}> {count} </div>
						<div className="rounded-circle stepButton" onClick={handlePlus} style={{textAlign:"center", width: "1.5rem", paddingLeft:"0px"}}><Icon.BsPlusCircle className="text-primary" style={{fontSize: "1.5rem", color:"blue", strokeWidth: '0'}} /></div>
					</Col>
				</Row>

			</>
		);
	}


	  return (	 
	  <>
		<Row xs={1} md={count?1:2} style={{padding: '10px', width: '100%'}}>
			<Col xs={12} md={count?6:12}>
				<CookiesProvider>

				<StepInputForm 
					quest={'Kids'} 
					initVal={0}
					minVal={0} 
					maxVal={7}
					aria-controls="collapse-elems"
					aria-expanded={count?true:false}
				/>
				</CookiesProvider>

			</Col>
			<Col xs={12} md={6} >
				<Collapse in={count?true:false}>
					<div id="collapse-elems">
						<CreatableSelect  
						  options={hobbies}
						  placeholder={"Kids hobbies..."}
						  value={listValue}
						  onChange={(e) => setListValue(e)}
						  isSearchable={true}
						  isMulti
						/>
						<CheckComponent quest="Parental support needed?" helpText="Do you need babysitting suport? Note, this is a financially non-compensated service offered on voluntary basis, does not replace full-time support" initValue="needParentalSupport"/>
						<CheckComponent quest="Offer parental support?" helpText="Can you offer parental support for other travellers, who are in need. This is a voluntary service, with no financial compensation. Selection has no impact on the offered optons, however you might be contacted" initValue="offerParentalSupport" />
					</div>
			  </Collapse>

			</Col>

		</Row>
		</>
	  );
}

const AdultProfession = ({who}) => {
	  const [listValue, setListValue] = useState(preferenceDetails.current.professions && preferenceDetails.current.professions[who]);
	  const shownText = {0: "Your competences", 1: "Your partner's competences"}[who];
	  useEffect(() => {
		if (who != undefined) preferenceDetails.current.professions[who] = listValue;
	  },[listValue]);

		return(
			<>
					<Row xs={1} md={2} style={{width: '100%'}}>
						<Col style={{fontWeight: 'bold'}}>
							{shownText}
						</Col>
						<Col>
							<Select 
							  options={positions}
							  placeholder={"Select "+shownText}
							  value={listValue}
							  onChange={(e) => setListValue(e)}
							  isSearchable={true}
							  isMulti
							/>
						</Col>
					</Row>
					<Row xs={1} style={{width: '100%'}}>
						<CheckComponent quest="Would you join our alumni?" helpText="Open to join FollowTheSun's alumni sport and recreational events, support other alumni members and new trevellers on voluntary, non-profit basis" initValue='joinsAlumni'/>
					</Row>
					<Row xs={1} style={{width: '100%'}}>
						<div className="sectionDivider"></div>
					</Row>
			</>
		);
	}

const AdultCount = () => {
	  const [count, setCount] = useState(preferenceDetails.current.adults ? preferenceDetails.current.adults : 1);
	  const [listValue, setListValue] = useState(preferenceDetails.current.professions && preferenceDetails.current.professions);

	useEffect(() => {
		updatePreferences('adults', count);

	},[count]);

	useEffect(() => {
		updatePreferences('professions', listValue);
	},[listValue]);

	const StepInputForm = ({quest,minVal, maxVal}) => {

		const handleMin = (e) => {
			e.stopPropagation();
			setCount(count - 1<minVal?minVal:count - 1);
		};

		const handlePlus = (e) => {
			e.stopPropagation();
			setCount(count + 1>maxVal?maxVal:count + 1);
		};

		  return (	 
			<>
				<Row xs={1} md={2} style={{width:"", paddingLeft:"0", marginLeft:"0px", margin: '10px'}}>
					<Col md={12} style={{textAlign: 'center', fontWeight: 'bold'}}>
						{quest}
					</Col>
					<Col md={12} style={{display: 'flex',flexWrap: 'nowrap',justifyContent: 'space-evenly'}}>
						<div className="rounded-circle stepButton" onClick={handleMin} style={{textAlign:"center", width: "1.5rem", paddingLeft:"0px"}}><Icon.BsDashCircle className="text-primary" style={{fontSize: "1.5rem", strokeWidth: '0'}} /></div>

						<div id={quest} style={{textAlign:"center", width: "1.5rem", fontWeight: 'bold'}}> {count} </div>
						<div className="rounded-circle stepButton" onClick={handlePlus} style={{textAlign:"center", width: "1.5rem", paddingLeft:"0px"}}><Icon.BsPlusCircle className="text-primary" style={{fontSize: "1.5rem", color:"blue", strokeWidth: '0'}} /></div>
					</Col>
				</Row>
			</>
		  );
	}


	  return (	 
		<Row xs={1} md={2} style={{padding: '10px', borderBottomStyle: 'solid',borderBottomColor: 'rgba(100,100,100,0.3)',borderBottomWidth:'1px'}}>
			<Col xs={12} md={6}>
				<StepInputForm quest={'Adults'} minVal={1} maxVal={5}/>
			</Col>
			<Col xs={12} md={6}>
					<div >
						<Select 
						  options={positions}
						  placeholder={"Travellers' professions..."}
						  value={listValue}
						  onChange={(e) => setListValue(e)}
						  isSearchable={true}
						  isMulti
						/>
					</div>
					<CheckComponent quest="Would you join our alumni?" helpText="Open to join FollowTheSun's alumni sport and recreational events, support other alumni members and new trevellers on voluntary, non-profit basis" initValue='joinsAlumni'/>
				
			</Col>
		</Row>
	  );
}



const ImageCarousel = ({imageList}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };	
  
  const handleClick = (e) => {
	  (e.target.className.includes("next-icon")|| e.target.className.includes("prev-")) && e.stopPropagation();
  };
 //		
 
	return (
		<Carousel style={{  justifyContent: 'space-around',display: 'flex'}} activeIndex={index} onSelect={handleSelect} onClick={handleClick} interval={null}>
			  {imageList && imageList.length > 0 && (imageList).map((img, i) =>
			  <Carousel.Item key={"pic"+i} >
					<Card.Img key={"pic1"+i} className="RentalCardImg" fluid="true" src={correctImgAddress(img)}></Card.Img>
				</Carousel.Item>	  
			  )}
		</Carousel>
	);
}



function lowlightPoint(e) {

	if (!map.current) return;
	if (!map.current.getSource('places')) return;
	if (!map.current.getLayer('hidden-points')) return;
	positionMarker.remove();

			//map.current.setFeatureState({source: 'places',id: id}, {hover: false});
			//map.current.setFilter('places', null);
			//map.current.setFilter('placesHi', ['in','id',1]);
	return;
}

function highlightPoint(coords,e) {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}
	if (!map.current) return;
	if (!map.current.getSource('places')) return;
	if (!map.current.getLayer('hidden-points')) return;
	positionMarker.remove();

	positionMarker.setLngLat({lon: coords[0], lat: coords[1]})
			.addTo(map.current);

			//map.current.setFeatureState({source: 'places',id: id}, {hover: true});
			//map.current.setFilter('places', ['==', ['get', 'id'], rentalsGEOJson[props.idx].properties.id]);
			//map.current.setFilter('placesHi', ['==', ['get', 'id'], id]);

	return;
}

const InsertSchoolCard = (props) => {
	function handleShow(e) {
		e.stopPropagation();
		//setActiveElement(true);
	}
  return (
		<Col key={props.school.id} id={props.school.id} className="rentalCardClass" >
          <Card  onClick={(e)=>handleShow(e) } ref={schCardRef[props.school.id]}>
			{props.navs &&(<AddPinPlan type={"Schools"} id={props.school.id} item={props.school} locator={true} isEmptyRef={props.isEmptyRef} compareModalRef={props.compareModalRef} rjsLikedList={rjsLikedList}/> )}

          <Card.Body style={{cursor: "pointer", padding: "5px"}}>
              <Card.Title >
				<Row className="g-2" style={{fontSize: "1rem", textAlign: "center"}}>
					<Col xs={9} style={{textAlign: "center", fontSize: "0.9rem", fontWeight: "bold", margin: "auto"}}>{props.school.name}</Col>
					<Col xs={3} style={{textAlign: "center", fontSize: "0.5rem", width: 30, height: 30 }}><Card.Img variant="top" style={{margin: "inherit" }} fluid="true" src={props.school.icon} /></Col>

				</Row>			  
			  </Card.Title>
              <Card.Text>
				<InsertParam name="Fee/year" value={props.school.fee&&props.school.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>
				{props.school.currency != "USD" && <InsertParam name="" value={props.school.fee&&props.school.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.school.currency}/>}

				<InsertParam name="Language" value={props.school.language} />
				<InsertParam name="Faculty" value={props.school.faculty} />
				<InsertParam name="City" value={props.school.city} />
              </Card.Text>
            </Card.Body>
          </Card>
		</Col>
);

}
//<Stack direction={props.stage=="compare" && isMobile ? "horizontal" : "vertical"}> map

const JobContent =({job,company}) => {

	return (
	<Row xs={1} md={2} style={{overflow: 'hidden'}}>
		<Col xs={12} md={6} >
			<AddPinPlan type={"Rentals"} id={job.properties.id} item={job} locator={true} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef}/>
			<div className="swiperMainContainer">
				<Image src={correctImgAddress(job.properties.icon)} ></Image>
			</div>						
				<CompanyParams company={company} />
		</Col>

		<Col xs={12} md={6} className="RentalcardBody" >
				<JobParams job={job} />
		</Col>
	</Row>
	);
}

const CompanyParams = ({ company}) => {
	const [compData, setCompData] = useState();

	useEffect(() => {

		!compData && 
				//fetch(`/scen_params`+scenarioList.current[id].id[0]+`.json`)
				fetch(`/companies.json`) //company
				.then(checkStatus)  // check the response of our APIs
				.then(response => response.json())
				.then(usefulData => {
					setCompData(usefulData);
				})
				.catch(e => console.log(`Error happenned when loading: ${e}`))
	},[])

	return (
		<>{compData && compData['Apple']['desc']}</>
	);
}

const JobParams = ({ job}) => {
	const [jobData, setJobData] = useState();

	useEffect(() => {

		!jobData && 
				//fetch(`/scen_params`+scenarioList.current[id].id[0]+`.json`)
				fetch(`/jobDetails.json`) //Job
				.then(checkStatus)  // check the response of our APIs
				.then(response => response.json())
				.then(usefulData => {
					setJobData(usefulData);
				})
				.catch(e => console.log(`Error happenned when loading: ${e}`))
	},[])	
	return (
		<div >	
		{jobData &&
		<>
			<Row xs={2} >
				<Col xs={6} className="highlight" key={"stampprice"}>
					<span >{jobData['salary'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
					<p className="subtitle">{jobData['currency']}</p>
				</Col>

			</Row>

			<div className="sectionDivider"></div>
			<h3>Features</h3>
			<Row xs={6}>
				<Col xs={2} className="features" >

				</Col>
			</Row>
			<div className="sectionDivider"></div>
			<h4>Description</h4>
			{jobData['desc']}
		</>
		}
		</div>
	
	);
}


const RentalContent =({rental, isEmptyRef, compareModalRef}) => {
									//<ImageCarousel imageList={rental.properties.imageList}/> 
	const imgList = typeof rental.properties.imageList === "string" ? JSON.parse(rental.properties.imageList) : rental.properties.imageList;

	return (
	<Row xs={1} >
		<Col xs={12}>
			<AddPinPlan type={"Rentals"} id={rental.properties.id} item={rental} locator={true} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef}/>
			<div className="swiperMainContainer">
				{imgList.length == 0 ?	
					<Image className="" fluid="true" src={correctImgAddress((rental.properties.image))}></Image>
					: 
					<ImageThumbsSwiper imageList={imgList}/>
				}
			</div>					
				<RentalParams rental={rental} />
		</Col>
	</Row>
	);
}

const ImageThumbsSwiper = ({ imageList}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

	return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiperMain"
      >
		  {imageList.map((imgElem,i) =>
			<SwiperSlide key={"swiperthumb"+i}>
				<img className="thumbMainImg" src={correctImgAddress(imgElem)} />
			</SwiperSlide>
		  )}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiperThumb"
      >
		  {imageList.map((imgElem,i) =>
			<SwiperSlide key={"thumb"+i}>
				<img className="thumbImg" src={correctImgAddress(imgElem)} />
			</SwiperSlide>
		  )}
      </Swiper>
    </>
	);
}


const RentalParams = ({ rental}) => {
	
	return (
<div >	
	<Row xs={rental.properties['size'] ? 3 : 2 } >
		<Col xs={rental.properties['size'] ? 4 : 6 } className="highlight" key={"stampprice"}>
			<span >{rental.properties['price'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
			<p className="subtitle">{rental.properties['currency']}</p>
		</Col>
		<Col xs={rental.properties['size'] ? 4 : 6 } className="highlight" key={"stampbed"}>
			<span >{rental.properties['bed']}</span>
			<p className="subtitle">beds</p>
		</Col>
		{rental.properties['size'] && <Col xs={rental.properties['size'] ? 4 : 6 } className="highlight" key={"stampsize"}>
			<span >{rental.properties['size'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
			<p className="subtitle">m2</p>
		</Col>}
	</Row>

	<div className="sectionDivider"></div>
	<h3>Features</h3>
	<Row xs={6}>
		{['pets','parking','balcony','garden','gym','pool','furnished'].map((item) => 
		<Col xs={2} className="features" key={"stamp"+item}>
			{{
				'pets': <><div><FaIcon.FaDog /></div><p className="subtitle" >Pets allowed</p></>,
				'parking': <><div><TbIcon.TbParking  /></div><p className="subtitle">Parking</p></>, //<TbParkingOff />
				'balcony': <><div><FaIcon.FaDog /></div><p className="subtitle">Balcony</p></>,
				'garden': <><div><FaIcon.FaTree  /></div><p className="subtitle">Garden</p></>,
				'gym': <><div><FaIcon.FaDog /></div><p className="subtitle">Gym</p></>,
				'size': <><div>{rental.properties[item]}<p className="subtitle">m2</p></div></>,
				'pool': <><div><FaIcon.FaSwimmingPool  /></div><p className="subtitle">Pool</p></>,
				'furnished': <><div><TbIcon.TbWashMachine /></div><p className="subtitle">Furnished</p></>
			}[item]}
		</Col>
		)}
	</Row>
	<div className="sectionDivider"></div>
	<h4>Description</h4>
	{rental.properties['desc']}
</div>
	
	);
}

		const RentalModal = forwardRef(({ isEmptyRef, initRentalsGEOJson},ref) => {
			console.log('RentalModal');
		  const modalRef = useRef(null);
	
		  //const [openRentalModal, setOpenRentalModal] = useState(false);

		  const [fullscreen, setFullscreen] = useState(false);
		  const [modalContent, setModalContent] = useState(false);
		  const type = useRef();
		  
		  const Content = ({rjs, elem}) => {
				console.log('Content',rjs, elem);

			  return ({ 
				'Rentals':	<RentalContent rental={elem} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef}/> ,
				'Jobs':	<JobContent job={elem} isEmptyRef={isEmptyRef} />
			  }[rjs]);
		  }


		  useImperativeHandle(ref, () => {
			return {
			  openModal(rental, rjs) { 
				type.current = rjs;
				const rentalContnet = rental.length > 0 ? rental : {"Rentals": rentalPlan.current,
														"Jobs": jobPlan.current}[rjs];
				const initList = {"Rentals": initRentalsGEOJson.current,
									"Jobs": initJobsGEOJson.current}[rjs];
				if (rentalContnet.length > 0 ) {
					const likedRentalElements = initList.filter((item,i) => rentalContnet.includes(item.properties.id) );
					likedRentalElements && setModalContent(likedRentalElements);
				}
				}
			};
		  }, []);

		  useEffect(() => {
				console.log('modalContent useEffect',modalContent);
		  },[modalContent]);


						//{activeElement && <Content rjs={activeElement.properties.price} elem={activeElement}/> }
		  
			return (
			  <Modal show={modalContent ? true:false} 
			  fullscreen={'md-down'} 
			  onHide={() => setModalContent(false)}
			  size="lg"
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  ref={modalRef}
			  contentClassName="modalContent"
			  dialogClassName="modalDialog"
			  >
				<Modal.Header closeButton />
				<Modal.Body style={{overflow: 'scroll', userSelect: 'none', scrollbarWidth: 'none'}}>
					<Carousel style={{  justifyContent: 'space-around',display: 'flex'}} interval={null}>
						{modalContent && modalContent.map((contentElem,i) => 
						  <Carousel.Item key={"rentElem"+i} >
								<Content rjs={type.current} elem={contentElem}/> 
							</Carousel.Item>	  
						  )}
					</Carousel>
				</Modal.Body>
			  </Modal>
			  );
		});
	
const InsertRentalCard = (props) => {
	//console.log('InsertRentalCard',rentalPlan);

	function handleShow(e) {
		e.stopPropagation();
		console.log('handleShow rentalModal',props.rentalModal, props.rental, );
		//setActiveElement(props.rental);
		props.rentalModal.current.openModal([props.rental.properties.id], "Rentals");
	}
	
	const imgList = typeof props.rental.properties.imageList === "string" ? JSON.parse(props.rental.properties.imageList) : props.rental.properties.imageList;


  return (
	<>
	<Col id={props.rental.properties.id} className="rentalCardClass" >
        <Card onClick={handleShow} style={{overflow: "hidden"}} ref={rentCardRef[props.rental.properties.id]} onMouseEnter={(e) => highlightPoint(props.rental.geometry.coordinates, e)} onMouseLeave={(e) => lowlightPoint(e)} >
			{props.navs &&(<AddPinPlan type={"Rentals"} id={props.rental.properties.id} item={props.rental} locator={true} isEmptyRef={props.isEmptyRef} compareModalRef={props.compareModalRef} rjsLikedList={rjsLikedList}/>)}

			<Row xs={props.stage=="compare" ? 2 : 1} className="g-2" >
			{props.toHide ? <Card.Img className="RentalCardImg" variant="top" fluid="true" src={'./sun-icon-png-17.jpg'} /> : 
			(imgList && imgList.length == 0 ? 
				<Card.Img className="RentalCardImg" variant="top" fluid="true" src={correctImgAddress((props.rental.properties.image))} /> 
				: <ImageCarousel imageList={imgList}/>) }
		  <div style={{overflow: "hidden"}} className={props.stage=="compare" ? "compare":undefined}>
		  </div>

          <Card.Body style={{cursor: "pointer"}}>
				<InsertParam name="City" value={props.rental.properties.city} />
				<InsertParam name="Price" value={props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>
				{props.rental.properties.currency != "USD" && <InsertParam name="" value={props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.rental.properties.currency}/>}

				{props.rental.properties.size >0 && (<InsertParam name="Size" value={props.rental.properties.size&&Math.trunc(props.rental.properties.size).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.7} unit="sqm" />)}
				<InsertParam name="Bedrooms" value={props.rental.properties.bed} />
				<InsertParam name="Bathrooms" value={props.rental.properties.bath} />
				<InsertParam name="ID" value={props.rental.properties.id} />
				<Row xs={3} className="g-2" style={{margin: 0, textAlign: "center"}}>
					<Col >
						{props.rental.properties.pets && <FaIcon.FaDog/>}
					</Col>
					<Col >
						{props.rental.properties.garage && <FaIcon.FaParking/>}
					</Col>
					<Col >
						{props.rental.properties.balcony && <FaIcon.FaParking/>}
					</Col>
				</Row>

          </Card.Body>

		</Row>
        </Card>
	</Col>
	</>
	);

}


const InsertJobCard = (props) => {
	//console.log('InsertJobCard',jobPlan);
	function handleShow(e) {
		e.stopPropagation();
		//setActiveElement(props.job);
		props.rentalModal.current.openModal([props.job.properties.id], "Jobs");
	}

  return (
		<Col key={props.idx} id={props.job.properties.id} className="rentalCardClass" onMouseEnter={(e) => highlightPoint(props.job.geometry.coordinates, e)} onMouseLeave={(e) => lowlightPoint(e)}>
          <Card  onClick={(e) => handleShow(e) } ref={jobCardRef[props.job.properties.id]} style={{backgroundImage: props.job.properties.icon}}>
			{props.navs &&(<AddPinPlan type={"Jobs"} id={props.job.properties.id} item={props.job} locator={true} isEmptyRef={props.isEmptyRef} compareModalRef={props.compareModalRef}/>)}		  

          <Card.Body style={{cursor: "pointer", padding: "5px"}}>
			<Row xs={props.stage=="compare" ? 2 : 1} className="g-4" style={{margin: 'auto'}}>
				<Row className="g-3" xs={props.stage=="compare" ? 1 : 3} style={{fontSize: "1rem", textAlign: "center", margin: 'auto'}}>
					<Col xs={12} style={{textAlign: "center", fontSize: "0.8rem", fontWeight: "bold", margin: "auto"}}>{props.job.properties.name}</Col>
					<Col xs={9} style={{textAlign: "center", fontSize: "0.8rem", margin: "auto"}}>{props.job.properties.company}</Col>
					<Col xs={3} style={{textAlign: "center", fontSize: "0.5rem", width: 40, height: 40, margin: "auto" }}>
					</Col>
				</Row>			  
				<Row className="g-3" xs={props.stage=="compare" ? 1 : 3} style={{fontSize: "1rem", textAlign: "center", margin: 'auto', padding: 0}}>
					<Col  xs={12} style={{margin: 'auto', padding: 0}}>
						<Row className="g-2" style={{margin: 'auto'}}>
							<Col xs={5}  style={{textAlign: "left", fontSize: "0.7rem", fontWeight: "normal", margin: 'auto'}}>Salary</Col>
							<Col xs={7} style={{textAlign: "right", fontSize: "1rem", fontWeight: "bold", margin: 'auto'}}>
								{props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								<span style={{fontSize: props.size ? props.size*0.6+"rem":"0.5rem"}}>USD</span>
							</Col>
						</Row>


						{props.job.properties.currency != "USD" && <InsertParam name="" value={props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.job.properties.currency}/>}


						<Row className="g-2" style={{margin: 'auto'}}>
							<Col xs={5}  style={{textAlign: "left", fontSize: "0.7rem", fontWeight: "normal", margin: 'auto'}}>Industry</Col>
							<Col xs={7} style={{textAlign: "right", fontSize: "1rem", fontWeight: "bold", margin: 'auto'}}>
								{props.job.properties.industry}
							</Col>
						</Row>

						<Row className="g-2" style={{margin: 'auto'}}>
							<Col xs={5}  style={{textAlign: "left", fontSize: "0.7rem", fontWeight: "normal", margin: 'auto'}}>City</Col>
							<Col xs={7} style={{textAlign: "right", fontSize: "0.7rem", fontWeight: "bold", margin: 'auto'}}>
								{props.job.properties.city}
							</Col>
						</Row>

					</Col>
				</Row>
			</Row>
            </Card.Body>

          </Card>
		</Col>
);

}

function correctImgAddress(src) {
	const httptext = 'img.rentberry.com';
    const options = {
      maxSizeMB: 0.1,
     // maxWidthOrHeight: 1080,
      useWebWorker: true,
    };
	var newSrc = src;
	if (src && src.indexOf(httptext)>0) {
		newSrc = newSrc+'@webp';
	}

  return (
	newSrc
  );
};


const InsertParam = (props) => {
	const nameLength = String(props.name).length;
	const p1 = String(props.value).length*1.1;
	const p2 = (1+(props.size?props.size/2:0));
	const p3 = props.unit?String(props.unit).length:0;
	const paramLength = p1*p2+p3;
	const ratio = Math.round(nameLength/(nameLength+paramLength)*12);
	
	return (
				<Row className="g-2" style={{margin: 0}}>
					<Col xs={paramLength>0?ratio:8}  style={{textAlign: "left", fontSize: "0.7rem", fontWeight: "normal", margin: 0}}>{props.name}</Col>
					<Col xs={paramLength>0?12-ratio:4} style={{textAlign: "right", fontSize: props.size ? props.size+"rem":"0.7rem", fontWeight: "bold", margin: 0}}>{props.value}<span style={{fontSize: props.size ? props.size*0.6+"rem":"0.5rem"}}>{props.unit}</span></Col>
				</Row>

	);
}


const AddPinPlan = ({type, id, item, locator,isEmptyRef, compareModalRef}) => {
	const likeList = {
		'Rentals': rentalPlan,
		'Jobs': jobPlan
	}[type];

	const [liked, setLiked] = useState(likeList.current.includes(id));

	function Liked (props) {

		  return (
				  {
					  false: <Icon.BsHeart />,
					  true: <Icon.BsHeartFill style={{color: 'red'}}/>
				  }[liked]
		  );
	}  

	function handlePlans(e,type,id,item) {
		e.stopPropagation();
		setLiked(!liked);
	}

  useEffect(() => {
		//item.properties.liked=!liked; //item.properties.liked ? !item.properties.liked : true;
		
		//const pointsInBound = map.current && map.current.queryRenderedFeatures({ layers: ['hidden-points'] });
		//pointsInBound.map(elem => 
		//	{
		//		elem.properties.liked = elem.properties.id == id && liked;
		//		console.log('pointsInBound liked',id,elem.properties.id,elem.properties.id == id);
		//	});
		//theList.current.map(elem => { if (elem.properties.id == id) {elem.properties.liked = liked}});
		
		
		//map.current.getSource('hiddenPlaces')?.setData(featureCollection(theList.current));

		likeList.current = liked ? ( !likeList.current.includes(id) ? [...likeList.current, id] : likeList.current ): likeList.current.filter((item) => item != id ) ;

		//isEmptyRef.current.markEmpty(rentalPlan.current.length>0 || jobPlan.current.length>0 || schoolPlan.current.length>0 ? false:true);
		compareModalRef.current.markEmpty(rentalPlan.current.length>0 && jobPlan.current.length>0 ? true : false);

  },[liked]);
	
	function handleLocator(e,type,id, item) {
		e.stopPropagation();
		console.log('handleLocator',e,type,id, item);
			const yCorrected = isMobile? window.innerHeight*0.5 : window.innerHeight*0.3 ;
			const xCorrected = window.innerWidth*0.4;
			const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: xCorrected} : {bottom: yCorrected };

		const coordinates = positionMarker.getLngLat();
		const eventParam = ["handleLocator",coordinates];
		map.current.easeTo({
							center: item.geometry.coordinates,
							padding: padding,
							zoom: 15
			},{eventParam});
		//positionMarker.remove();
		visibleCard.current = 0;
	}


	return (
		<>
			{locator && <div className="listsLeft" onClick={(e) => {handleLocator(e,type,id, item)}} >
				<TbIcon.TbCurrentLocation /> 
			</div>}
			  
			  <div className="listsRight" id={"listsLeft"+id} onClick={(e) => handlePlans(e,type,id,item)} >
				<Liked type={type} id={id} key={id} /> 
			  </div>
		</>
	);
}

const handleFetchData = async () => {
    const response = await fetch(`https://safe2change.com/new/Register.php?o=Budapest&d=`+preferenceDetails.current.destination[0].label,{
        mode: 'no-cors',
        credentials: 'include',
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })		  
		.catch((e) => console.error(`An error occurred: ${e}`));
    //const data = await response.json();
    console.log('handleFetchData',response);
}



  
const FilterListComponent = ({value,i,rjs}) => {
	const [listValue, setListValue] = useState(value[2]);
	console.log('FilterListComponent',value[1]);
		const dataInput = value[1].map(
		  item => ({label: item, value: item}));

  useEffect(() => {
		applyListFilters(listValue,i,rjs);
  },[listValue]);

	return (
					<div key={i} style={{padding: '10px', borderBottomStyle: 'solid',borderBottomColor: 'rgba(100,100,100,0.3)',borderBottomWidth:'1px'}}>
						{i}
						<Select 
						  options={dataInput}
						  placeholder={"Select "+i+"..."}
						  value={listValue}
						  onChange={(e) => setListValue(e)}
						  isSearchable={true}
						  isMulti
						/>
					</div>
	);
}


const FilterRangeComponent = ({value,i,rjs}) => {
	const [rangeValues, setRangeValues] = useState([value[3],value[4]]);

	return (
					<div style={{padding: '10px', position: 'relative', margin: '20px', borderBottomStyle: 'solid',borderBottomColor: 'rgba(100,100,100,0.3)',borderBottomWidth:'1px'}}>
						{i}
						<MultiRangeSlider
						  min={value[1]} 
						  max={value[2]} 
						  currentMin={rangeValues[0]}
						  currentMax={rangeValues[1]}
						  onChange={(e) => true}
						  onMouseUp={(e) => applyRangeFilters(e,i,rjs)}
						/>
					</div>
	);
}

const FilterCheckComponent = ({value,i,rjs}) => {
	const [currentValue, setCurrentValue] = useState(value[1]);

  useEffect(() => {
	  if (i=="liked") {
		applyLikedFilters(currentValue,i,rjs);
	  } else {
		applyCheckFilters(currentValue,i,rjs);
	  }
  },[currentValue]);
	
	
	return (
							<Row style={{padding: '20px', borderBottomStyle: 'solid',borderBottomColor: 'rgba(100,100,100,0.3)',borderBottomWidth:'1px'}}>
								<Col xs={8} style={{margin: 'auto'}}>
									{i}
								</Col>
								<Col xs={4} style={{margin: 'auto'}}>
									<Container className="">
									  <Form>
										<Form.Check
										  type="switch"
										  id="item-switch"
										  
										  onChange={(e)=> setCurrentValue(e.currentTarget.checked)}
										  checked={currentValue}
										/>
									  </Form>
									</Container>
								</Col>
							</Row>
	);
}


const FilterComponent = ({value,i,rjs}) => {
			return ({
				'range':
					<FilterRangeComponent value={value} i={i} rjs={rjs}/>,
				'check':
					<FilterCheckComponent value={value} i={i} rjs={rjs}/>,
				'list':
					<FilterListComponent value={value} i={i} rjs={rjs}/>,
			}[value[0]]);
			
			}


	const AddFilterItem = forwardRef(({type},ref) => {
		//const [filterValue,setFilterValue] = useState(item[3] ? item[3] : item[2]);
			const [rjs,setRjs] = useState(type);
		console.log('AddFilterItem',rjs);
			var filterValue = {};

			const geoJsonList = {
				'Rentals':initRentalsGEOJson.current,
				'Jobs':initJobsGEOJson.current
			}[rjs];		
			
			//const pointsInBound = map.current.queryRenderedFeatures({ layers: ['hidden-points'] });

	useImperativeHandle(ref, () => {
		return {
			changeFilters(page) { 
				console.log('AddFilterItem useImperativeHandle',page);
				setRjs(page);
			}
		};
	}, []);			
		
			filterParams[rjs].map(param => {
				
				if (filterCategories[param] == 'range') {
					const min = geoJsonList.reduce((agg, d) => Math.min(agg, d.properties[param]), Infinity);
					const max = geoJsonList.reduce((agg, d) => Math.max(agg, d.properties[param]), 0);
					const currentMin = filtersSet.current[rjs][param] ? filtersSet.current[rjs][param].min : min; 
					const currentMax = filtersSet.current[rjs][param] ? filtersSet.current[rjs][param].max : max;
					filterValue[param] = [ 'range' ,min, max, currentMin, currentMax]; 
					console.log('filterValue',param,min, max, filtersSet.current, filterValue[param]);
				} else if (filterCategories[param] == 'check') {
					const currentValue = filtersSet.current[rjs][param] ? filtersSet.current[rjs][param] : false; // pointsInBound.reduce((agg, d) => Math.min(agg, d.properties[param]), Infinity);
					filterValue[param] = [ 'check', currentValue ]; 
					
				} else if (filterCategories[param] == 'list') {
					//const allParamList = geoJsonList.reduce((agg, d) => !agg.includes(d.properties[param]), "");
					const allParamList = geoJsonList.map(item => item.properties[param]);
					const uniqueParamList = allParamList.filter((item,i) => allParamList.indexOf(item) == i );

					console.log('filterValue allParamList',uniqueParamList);
					filterValue[param] = [ 'list', uniqueParamList, filtersSet.current[rjs][param] ]; 
					
				}
			});

			return(
			Object.keys(filterValue).map((key,i) => <FilterComponent key={key+i} value={filterValue[key]}  i={key} rjs={rjs}/>));
		});

		function applyRangeFilters (e,item,rjs) {
			const visibleItems = map.current.queryRenderedFeatures({ layers: ['hidden-points'] });
			
			const newFilter = {
				'price':[['>=', ["number", ['get', 'price']], Number(e.min)], ['<=', ["number", ['get', 'price']], Number(e.max)]],
				'bed':[['>=', ["number", ['get', 'bed']], Number(e.min)], ['<=', ["number", ['get', 'bed']], Number(e.max)]],
				'bath':[['>=', ["number", ['get', 'bath']], Number(e.min)], ['<=', ["number", ['get', 'bath']], Number(e.max)]],
				'size':[['>=', ["number", ['get', 'size']], Number(e.min)], ['<=', ["number", ['get', 'size']], Number(e.max)]],
				'salary':[['>=', ["number", ['get', 'salary']], Number(e.min)], ['<=', ["number", ['get', 'salary']], Number(e.max)]]
			}[item];
			
			var legacyFilters = map.current.getLayer('hidden-points') && map.current.getFilter('hidden-points');

			filtersSet.current[rjs][item] = e;

			legacyFilters = legacyFilters && legacyFilters.filter(filterItem => !filterItem[1].includes(item));
			
			const applyFilter = legacyFilters ? [...legacyFilters,...newFilter] : ['all',...newFilter];
			console.log('applyRangeFilters',applyFilter, filtersSet.current,visibleItems);
			map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',applyFilter);
			console.log('applyRangeFilters2',applyFilter, filtersSet.current,visibleItems, rentalPlan.current);
		}

		function applyCheckFilters (e,item,rjs) {
			var legacyFilters = map.current.getLayer('hidden-points') && map.current.getFilter('hidden-points');
			legacyFilters = legacyFilters && legacyFilters.filter(filterItem => !filterItem[1].includes(item)); // removal of the filter

			if (e) {
				const newFilter = {
					'pets':[['==', ['get', 'pets'], true]],
					'garage':[['==', ['get', 'garage'], true]],
					'balcony':[['==', ['get', 'balcony'], true]]
				}[item];
				
				filtersSet.current[rjs][item] = true;
				const applyFilter = legacyFilters ? [...legacyFilters,...newFilter] : newFilter;
				
				console.log('applyCheckFilters',item,e,applyFilter, filtersSet.current);
				map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',applyFilter);
			} else {
				filtersSet.current[rjs][item] = false;
				
				console.log('applyCheckFilters',item,e,legacyFilters, filtersSet.current);
				map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',legacyFilters);
			}

		}

		function applyLikedFilters (e,item,rjs) {
			var legacyFilters = map.current.getFilter('hidden-points');
			legacyFilters = legacyFilters && legacyFilters.filter(filterItem => !filterItem.includes('any')); // removal of the filter

			const likeList = {
				'Rentals': rentalPlan.current,
				'Jobs': jobPlan.current
			}[rjs];
			console.log('applyLikedFilters',legacyFilters, likeList.current);

			if (e) {
				const newFilter = likeList.map(id => ['==', ['get', 'id'], id]);
				
				filtersSet.current[rjs][item] = true;
				//const applyFilter = likeList.length == 0 ? legacyFilters : (legacyFilters ? [...legacyFilters,['any',...newFilter]] : ['any',...newFilter]);
				const applyFilter = legacyFilters ? [...legacyFilters,['any',...newFilter]] : ['any',...newFilter];
				
				console.log('applyLikedFilters2',item,e,applyFilter, filtersSet.current, likeList);
				map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',applyFilter);
			} else {
				filtersSet.current[rjs][item] = false;
				
				console.log('applyLikedFilters3',item,e,legacyFilters, filtersSet.current, likeList);
				map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',legacyFilters);
			}

		}

		function applyListFilters (e,item,rjs) {
			const paramList = e ? e.map(elem => elem.value) : [];
			const switchItem = (elem) => ({
					'position':['==', ['get', 'position'], elem],
					'industry':['==', ['get', 'industry'], elem],
					'company':['==', ['get', 'company'], elem]
				}[item]);
			const filterElements = paramList.map(elem => switchItem(elem));
			const newFilter = ['any', ...filterElements];
			
			var legacyFilters = map.current.getFilter('hidden-points');

			filtersSet.current[rjs][item] = paramList;

			legacyFilters = legacyFilters && legacyFilters.filter(filterItem => !(filterItem[0].includes('any')&&filterItem[1]&&filterItem[1][1]&&filterItem[1][1].includes(item)));
			
			const applyFilter = filterElements.length > 0 ? (legacyFilters ? [...legacyFilters,newFilter] : ['all',newFilter]) : legacyFilters;
			console.log('applyListFilters',applyFilter, filtersSet.current,newFilter);
			map.current.getLayer('hidden-points') && map.current.setFilter('hidden-points',applyFilter);
		}


const AddFilterOffcanvas = ({type,swipePageFilter}) => {
  const [showFilter,setShowFilter] = useState(false);

	const handleClose = () => {

		setShowFilter(false);
	}

	const handleClick = (e) => {
		//console.log('handleClick');
		e.stopPropagation();
	}
	const handleFilter = (e) => {
		e.stopPropagation();
		setShowFilter(true);
	}
	


	return (
	<>
		<Icon.BsFilter onClick={handleFilter}/>

		<Offcanvas show={showFilter} onClick={handleClick} scroll={true} onHide={handleClose} backdrop={false} placement={isMobile? 'bottom' : 'end'} name={'end'} style={{zIndex: 10000}}>
			<Offcanvas.Header closeButton />
			<Offcanvas.Body >
				<AddFilterItem type={type} ref={swipePageFilter}/>

			</Offcanvas.Body>
		</Offcanvas>
	</>
	);
}

const RentalCards = ({type, rentals,navs,condensed, trigger, rentalModal, isEmptyRef, compareModalRef,rjsLikedList, swipePageFilter}) => {
	const sortIncrease = useRef(true);

	const handleSort = (e) => {
		e.stopPropagation();
		sortIncrease.current = !sortIncrease.current;
		rentalsGEOJson.current.sort((a,b) => a.properties.price > b.properties.price ? (sortIncrease.current ? 1 : -1) : (sortIncrease.current? -1 : 1))
		return ;
	}

	const handleCompare = (e) => {
		e.stopPropagation();
		rentalModal.current.openModal([],type);
		return ;
	}

  return (
	<>
		<Card style={{ width: '100%', height: condensed ? '':'100%' }} >
			<Card.Header className="cardHeader" >
				<Row>
					<Col xs={6} md={9}>  {type}</Col>
					<Col xs={2} md={1} style={{"textAlign": "right", cursor: 'pointer'}} >
						<AddFilterOffcanvas type={type} swipePageFilter={swipePageFilter}/>
					</Col>
					<Col xs={2} md={1} style={{"textAlign": "right", cursor: 'pointer'}} onClick={handleSort}><TbIcon.TbArrowsSort /></Col>	
					<Col xs={2} md={1} style={{"textAlign": "right", cursor: 'pointer'}} onClick={handleCompare}><TbIcon.TbGitCompare /></Col>	
				</Row>
			</Card.Header>
			<AddRentalRows type={type} rentals={rentals} navs={navs} map={map} rentalModal={rentalModal} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList}/>
		</Card>

	</>
  );
}
//	        {rentalCardsArray.filter((rental,i) => (rentalsGEOJson.map((rentalGeo) => (rentalGeo.id == rental.props.id))))}


const AddRentalRows = ({type, rentals, navs, map, rentalModal, isEmptyRef, compareModalRef,rjsLikedList}) => {

	const [maxRows,setMaxRows] = useState(rowsWindow.current);
	const preFeatureIDs = useRef(0);
	const xbbox = isMobile && window.innerWidth > window.innerHeight ? 
		[[refSwiper.current&&refSwiper.current.getBoundingClientRect().width,0],[window.innerWidth,refSwiper.current&&refSwiper.current.getBoundingClientRect().bottom]]
		: [[0,0],[window.innerWidth,refSwiper.current&&refSwiper.current.getBoundingClientRect().top-37]];
	const xpointsInBound = map.current && map.current.getLayer('hidden-points') ? map.current.queryRenderedFeatures(xbbox,{ layers: ['hidden-points'] }) : rentalsGEOJson.current;
	const xallIDs = xpointsInBound.map(elem => elem.properties.id);
	const xuniqueIDs = xallIDs.filter((elem, pos) => xallIDs.indexOf(elem) == pos); 
	const uniquePoints = useRef(xpointsInBound.filter((elem, pos) => xuniqueIDs.indexOf(elem.properties.id) == pos));
	const [rowsArray,setRowsArray] = useState(() => 
		Array(maxRows)
		.fill(true)
		.map((_,i) => 
			<RentalRows type={type} toHide={i<maxRows-rowsWindow.current ? true:false} rentals={rentals} key={i} row={i} navs={navs}  rentalModal={rentalModal} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList}/>));

	const handleScrl = (e) => {
		if (e) {
			if (isMobile) { 
				//(e.target.getBoundingClientRect().top+50 > e.target.childNodes[0].childNodes[visibleCard.current].getBoundingClientRect().bottom) && visibleCard.current < e.target.childNodes[0].childNodes.length-1 && visibleCard.current++;
				//(e.target.getBoundingClientRect().top+50 < e.target.childNodes[0].childNodes[visibleCard.current].getBoundingClientRect().top) && visibleCard.current >0 && visibleCard.current--;
				
				const visibleItem = Array.from(e.target.childNodes[0].childNodes).filter((item,i) => 
					isBetween((item.getBoundingClientRect().top+item.getBoundingClientRect().bottom)/2,[e.target.getBoundingClientRect().top, e.target.getBoundingClientRect().bottom])
				);
				const visibleElem = Array.from(e.target.childNodes[0].childNodes).indexOf(visibleItem[0]);
				//if (prev != visibleCard.current) {
				if (uniquePoints.current[visibleElem]) {
					//map.current.easeTo({
					//	center: uniquePoints.current[visibleElem].geometry.coordinates});
				
					const visiblePopup = popups.current.map(pps => {
						if (pps.getElement()) {
							if (pps.options.id == uniquePoints.current[visibleElem].properties.id) {
									pps.getElement().childNodes[1].style.backgroundColor = 'blue';
									pps.getElement().childNodes[1].style.color = 'white'; 
							} else {
									pps.getElement().childNodes[1].style.backgroundColor = 'white';
									pps.getElement().childNodes[1].style.color = 'grey'; 
							}
						}
					});
					//if (visiblePopup.length > 0) visiblePopup[0].getElement().childNodes[1].style.backgroundColor = 'green';


					positionMarker.remove();
					positionMarker.setLngLat({lon: uniquePoints.current[visibleElem].geometry.coordinates[0], lat: uniquePoints.current[visibleElem].geometry.coordinates[1]})
						.addTo(map.current);
					//}
				}
			}
			e.target.children[0].children[0] && e.target.children[0].getBoundingClientRect().bottom < window.innerHeight + e.target.children[0].children[0].getBoundingClientRect().height && setMaxRows(current => current+Math.min(3, Math.trunc((uniquePoints.current.length)/cardsPerRow-current+1)));
			e.target.children[0].children[0] && e.target.children[0].getBoundingClientRect().bottom > window.innerHeight + 5*e.target.children[0].children[0].getBoundingClientRect().height && setMaxRows(current => current-3 > rowsWindow.current ? current-3 : rowsWindow.current );
		}
	};

	useEffect(() => {
	    setRowsArray(() => Array(maxRows).fill(true).map((_,i) => <RentalRows type={type} toHide={i<maxRows-rowsWindow.current ? true:false} rentals={uniquePoints.current} key={i} row={i} navs={navs}  rentalModal={rentalModal} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList}/>));
	},[maxRows]);

	
    useEffect(() => {
       if (!map.current) return;
	   map.current.on('idle', (e) => {

			if (refSwiper.current && refSwiper.current.swiper) {
				const swiperState = {2:'Rentals',
							 3: 'Jobs',
							 4: 'Schools'
							}[refSwiper.current.swiper.activeIndex];

				if (swiperState == type) {
					const bbox = isMobile && window.innerWidth > window.innerHeight ? 
						[[refSwiper.current&&refSwiper.current.getBoundingClientRect().width,0],[window.innerWidth,refSwiper.current&&refSwiper.current.getBoundingClientRect().bottom]]
						: [[0,0],[window.innerWidth,refSwiper.current&&refSwiper.current.getBoundingClientRect().top-37]];

					const pointsInBound = e.target.queryRenderedFeatures(bbox,{ layers: ['hidden-points'] });
					const allIDs = pointsInBound.map(elem => elem.properties.id);
					const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
					uniquePoints.current = pointsInBound.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos); 

					if (JSON.stringify(uniqueIDs) !== JSON.stringify(preFeatureIDs.current)) {  // in case of dataload, zoom, drag
						preFeatureIDs.current = uniqueIDs;
						setRowsArray(() => Array(maxRows).fill(true).map((_,i) => <RentalRows type={type} toHide={i<maxRows-rowsWindow.current ? true:false} rentals={uniquePoints.current} key={i} row={i} navs={navs}  rentalModal={rentalModal} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList}/>));
					}
				}
			}
        });
		return () => map.current.off('idle');

  },[]);
	
return (
		<Card.Body className="RentalcardBody" onScroll={handleScrl} >
			<Row ref={rentalcardBodyRef} xs={cardsPerRow} md={cardsPerRow} lg={cardsPerRow} className={"g-"+cardsPerRow} >
				{rowsArray}
			</Row>
		</Card.Body>
	);	
}

const RentalRows = ({type, row,rentals,toHide, navs, rentalModal, isEmptyRef, compareModalRef, rjsLikedList}) => {

	if (rentals.length - row*cardsPerRow < 1) return;
	const listElems = rentals.length - row*cardsPerRow > cardsPerRow ? new Array(cardsPerRow).fill(true) : new Array(rentals.length - row*cardsPerRow).fill(true);
	return listElems.map((_,i) => type == 'Rentals' ? 
		<InsertRentalCard toHide={toHide} key={rentals[row*cardsPerRow+i].properties.id} from={'addCards'} rental={rentals[row*cardsPerRow+i]} navs={navs} rentalModal={rentalModal} isEmptyRef={isEmptyRef}  compareModalRef={compareModalRef} rjsLikedList={rjsLikedList}/> : 
	 	<InsertJobCard key={rentals[row*cardsPerRow+i].properties.id} job={rentals[row*cardsPerRow+i]} navs={navs} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rentalModal={rentalModal}/>);
};

function createScenarios(categories) {
	console.log('createScenarios',categories);
	var scenariosCreated = []
	var rentalElem;
	var jobElem;
	var schoolElem;

	const rentalList: (number|object)[] = [rentalPlan.current, false].flat();
	const jobList: (number|object)[] = [ jobPlan.current, false].flat();
	const schoolList: (number|object)[] = [ schoolPlan.current, false].flat();
	
	
	const includeRental = rentalList.length > 1;
	const includeJob = jobList.length > 1;
	const includeSchool = schoolList.length > 1;
	
	const inclusionList = [includeRental, includeJob, includeSchool];
	var matrixRow=[];
	var k=0;
	
	rentalList.map((rentalItem) => {
		rentalElem = rentalItem ? rentalsGEOJson.current.filter((itemR,i) => rentalItem == itemR.properties.id)[0] : false;
		jobList.map((jobItem) => {
			jobElem = jobItem ? jobsGEOJson.current.filter((itemJ,j) => jobItem == itemJ.properties.id)[0] : false;
			schoolList.map((schoolItem) => {
				schoolElem = schoolItem ? schoolDetals.filter((itemS,s) => schoolItem == itemS.id)[0] : false;
				matrixRow = [];

				inclusionList.map((inclusion, lIndex) => {
					if (inclusion) {
						matrixRow = [...matrixRow,{
							0: rentalElem,
							1: jobElem,
							2: schoolElem
						}[lIndex]]}

					}
				);

				if (rentalElem || jobElem || schoolElem) 
				{
					scenariosCreated = [...scenariosCreated, {id: [rentalElem ? rentalElem.properties.id:false,jobElem ? jobElem.properties.id:false, schoolElem? schoolElem.id : false], matrix: matrixRow}];
				}

			});
		})
	});	
	
	amendScenarioList(scenariosCreated);
	console.log('scenariosCreated',scenariosCreated);
	return;

}





function NoJob() {
	return (
	<Col className="rentalCardClass" >
        <Card style={{overflow: "hidden", textAlign: "center"}} >
		<Card.Header style={{overflow: "hidden", textAlign: "center"}}>You didn't select job yet</Card.Header>


          <Card.Body style={{cursor: "pointer"}}>
      <Button variant="outline-primary" size="sm">
        I alreayd have job
      </Button>{' '}
      <Button variant="primary" size="sm">
        Find a job
      </Button>
          </Card.Body>

        </Card>
	</Col>
	);
}

const NoSchool = ({school}) => {
//		<Card.Header style={{overflow: "hidden", textAlign: "center"}}></Card.Header>
	//const [visible, setVisible] = useState(school ? school == 'noschool' ? 'none' : 'block':'none');

	const handleFindSchool = (e) => {
		e.stopPropagation();
		refSwiper.current.swiper.slideTo(4,0,true);
		//closeCompareModal();
	}

	const handleNoSchool = (e) => {
		e.stopPropagation();
		console.log('handleNoSchool1',school);
		//school='noschool';
		//setVisible('none');
		console.log('handleNoSchool2',school);
	}

	return (
	<Col className="rentalCardClass" style={{cursor: "pointer", fontSize: '0.7rem', fontWeight: 'bold', padding: '0', margin: 'auto'}}>
        <div style={{overflow: "hidden", textAlign: "center"}} > Add school(s)<br/>
		</div>
		<AiIcon.AiOutlinePlusCircle className="addMore" onClick={handleFindSchool} />
	</Col>
	);
}	

const AddPie = ({id, categories}) => {
	const [loadingComp, setLoadingComp] = useState(scenarioList.current[id].scores ? false : true );
	var score, title;

	useEffect(() => {

		loadingComp && 
				//fetch(`/scen_params`+scenarioList.current[id].id[0]+`.json`)
				fetch("https://safe2change.com/scenarios.php?rental="+scenarioList.current[id].id[0]+"&job="+scenarioList.current[id].id[1])
				.then(checkStatus)  // check the response of our APIs
				.then(response => response.json())
				.then(usefulData => {
				if (usefulData && usefulData.scores)  {
					scenarioList.current[id].scores = usefulData.scores;
					scenarioList.current[id].title = usefulData.title;
					scenarioList.current[id].reference = usefulData.reference;
					scenarioList.current[id].evaluation = usefulData.evaluation;
					}
					setLoadingComp(false);
				})
				.catch(e => console.log(`Error happenned when loading: ${e}`))
	},[loadingComp])
	//						{scenarioList.current[id].title ? scenarioList.current[id].title:"Option was not yet evaluated"}

	
	return (
				<Row className="g-2" style={{width: '100%'}}>
					<Col xs={9} className="accordHeader">
						{scenarioList.current[id].matrix[0]&&
						<>
							<p>
								<b>Apartment</b>: {scenarioList.current[id].matrix[0].properties.bed} bdr(s): 
								<b>{scenarioList.current[id].matrix[0].properties.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")},-</b>
							</p>
						</>}
						{scenarioList.current[id].matrix[1]&&
						<>
							<p>
								<b>Job</b>: {scenarioList.current[id].matrix[1].properties.position} at {scenarioList.current[id].matrix[1].properties.company}: 
								<b>{scenarioList.current[id].matrix[1].properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")},-</b>
							</p>
						</>}
						{scenarioList.current[id].matrix[2]&&
						<>
							<p>
								School: {scenarioList.current[id].matrix[2].properties.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							</p>
						</>}
					</Col>
					<Col xs={3} style={{textAlign: "center", fontWeight: "bold"}}>
						{categories[0] && <Pie percentage={scenarioList.current[id].scores ? scenarioList.current[id].scores[categories[0].value]*10 : 0} colour={'blue'} />}
					</Col>
				</Row>
	);
}



const AddScenEvaluation = ({id, categories, shrink}) => {
	const [loadingComp, setLoadingComp] = useState(scenarioList.current[id].scores ? false : true );

	useEffect(() => {

		loadingComp && 
				//fetch(`/scen_params`+scenarioList.current[id].id[0]+`.json`)
				fetch(`/scen_params.json`)
				.then(checkStatus)  // check the response of our APIs
				.then(response => response.json())
				.then(usefulData => {
					if (usefulData) {
						scenarioList.current[id].scores = usefulData.scores;
						scenarioList.current[id].title = usefulData.title;
						scenarioList.current[id].reference = usefulData.reference;

						Object.keys(usefulData.scores).map((key) =>  
							{if (!JSON.stringify(categories).includes(JSON.stringify({ value: key, label: key}))) {
								categories = [...categories,{ value: key, label: key}];
							//setCategories(current => [...current,key] );
							}});
					}
					setLoadingComp(false);
				})
				.catch(e => console.log(`Error happenned when loading: ${e}`))
	},[loadingComp])
	
	const CommuteChart = ({path,nodeFit,expand}) => {
		var mapSize;
		if (nodeFit) {
			const box = nodeFit.getBoundingClientRect();
			mapSize = Math.round(box.width/(isMobile ? 1 : 2))+"x"+Math.round(box.height);
			console.log('CommuteChart box size',box);
		} else {
			mapSize = "300x200";
		}
		console.log('CommuteChart',path,mapSize,nodeFit&&nodeFit.getBoundingClientRect());
		
		console.log('CommuteChart',"https://api.mapbox.com/styles/v1/mapbox/navigation-day-v1/static/"
			//+bbox+"/"
			+path+"/"
			+"auto/"
			+mapSize
			+"?padding=auto&access_token="+mapboxgl.accessToken ); //nodeFit.current.getBoundingClientRect().height


		return (
		<>
			
			<img src={"https://api.mapbox.com/styles/v1/mapbox/navigation-day-v1/static/"
			//+bbox+"/"
			+path+"/"
			+"auto/"
			+mapSize
			+"?access_token="+mapboxgl.accessToken} alt="" style={{position: 'relative'}} />
		</>
		);
	}

	const SecurityChart = ({reference}) => {
		
		const option = {
			  silent: true,
			  series: [
				{
				  radius: ['15%', '80%'],
				  type: 'sunburst',
				  sort: undefined,
				  emphasis: {
					focus: 'ancestor'
				  },
				  data: [
					{
					  value: 8,
					  children: [
						{
						  value: 4,
						  children: [
							{
							  value: 2
							},
							{
							  value: 1
							},
							{
							  value: 1
							},
							{
							  value: 0.5
							}
						  ]
						},
						{
						  value: 2
						}
					  ]
					},
					{
					  value: 4,
					  children: [
						{
						  children: [
							{
							  value: 2
							}
						  ]
						}
					  ]
					},
					{
					  value: 4,
					  children: [
						{
						  children: [
							{
							  value: 2
							}
						  ]
						}
					  ]
					},
					{
					  value: 3,
					  children: [
						{
						  children: [
							{
							  value: 1
							}
						  ]
						}
					  ]
					}
				  ],
				  label: {
					color: '#000',
					textBorderColor: '#fff',
					textBorderWidth: 2,
					formatter: function (param) {
					  var depth = param.treePathInfo.length;
					  if (depth === 2) {
						return 'radial';
					  } else if (depth === 3) {
						return 'tangential';
					  } else if (depth === 4) {
						return '0';
					  }
					  return '';
					}
				  },
				  levels: [
					{},
					{
					  itemStyle: {
						color: '#CD4949'
					  },
					  label: {
						rotate: 'radial'
					  }
					},
					{
					  itemStyle: {
						color: '#F47251'
					  },
					  label: {
						rotate: 'tangential'
					  }
					},
					{
					  itemStyle: {
						color: '#FFC75F'
					  },
					  label: {
						rotate: 0
					  }
					}
				  ]
				}
			  ]
			};
		
		 		
		return (
			<ReactEcharts 
			style={{minHeight: '30vh', width: isMobile? '100vw' : '50vw'}}
			opts={{renderer: 'svg'}}  
			option={option} />
		);
	}

	const RecognitionChart = ({reference, personalIncome, costs}) => {
		
		const salaryRef = reference.income.salary[0].period == "monthly" ? reference.income.salary[0].average : 
			Math.round(reference.income.salary[0].average/12);
		const income = Math.round(personalIncome && personalIncome.salary/12);
		//var seriesData = [ [income, income, income, income] ];
		const seriesData = reference.income.salary.map(item => [item.min/12, (item.q1/12), item.median/12, (item.q2/12), item.max/12]);
		//seriesData = [...seriesData, [income, income, income, income]];
		console.log('RecognitionChart',reference, personalIncome, costs, seriesData);

		const option = {
		  title: [
			{
			  text: 'Nearby salaries',
			  left: 'center',
			   textStyle: {
				fontWeight: 'bold',
				fontSize: 14,
				lineHeight: 20
			  },   
			}
		  ],
		  dataset: [
			{
			  source: seriesData
			},
			{
			  transform: {
				type: 'boxplot',
				config: { itemNameFormatter: ['egy','ketto'] }
			  }
			},
			{
			  fromDatasetIndex: 1,
			  fromTransformResult: 1
			}
		  ],
		  tooltip: {
			trigger: 'item',
			axisPointer: {
			  type: 'shadow'
			}
		  },
		  grid: {
			left: '10%',
			right: '10%',
			bottom: '15%',
			padding: '50px'
		  },
		  xAxis: {
			type: 'category',
			boundaryGap: true,
			nameGap: 30,
			splitArea: {
			  show: false
			},
			splitLine: {
			  show: false
			}
		  },
		  yAxis: {
			type: 'value',
			name: 'USD',
			splitArea: {
			  show: true
			}
		  },
		  series: [
			{
			  name: 'boxplot',
			  type: 'boxplot',
			  datasetIndex: 1,
				itemStyle: {
				  color: '#b8c5f2'
				},

			markLine: {
			  data: [
				// 1st line we want to draw
				[
				  // start point of the line
				  // we have to defined line attributes only here (not in the end point)
				  {
					xAxis: 'min',
					yAxis: income,
					symbol: 'none',
					lineStyle: {
					  type: "solid",
						color: "#F00",
					  normal: {
					  type: "solid",
						color: "#F00"
					  }
					},
					label: {
					  normal: {
						show: true,
						position: 'end',
						formatter: 'Your salary'
					  }
					}
				  },
				  // end point of the line
				  {
					xAxis: 'max',
					yAxis: income,
					symbol: 'none'
				  }
				]
			  ]
			}

			},
			{
			  name: 'Your salary',
			  type: 'line',
			  datasetIndex: 2
			}
		  ]
		}; 		
		return (
			<ReactEcharts 
			style={{minHeight: '30vh', width: isMobile? '100vw' : '50vw'}}
			opts={{renderer: 'svg'}}  
			option={option} />
		);
	}



	const AffordabilityChart = ({reference, income}) => {
		console.log('AffordabilityChart');
		const thisRef = useRef();
		var dataset = [];
		var costRef = 0;
		var costMy = 0;
		var labels = [];
		var colors = ['#0d6efd']; //#0d6efd, #e9ecef
		const paramLength = Object.keys(reference.expenditure.costs).length;
		const labelOptions = {
			position: 'inside',
			show: true,
			formatter: '{a}',
			fontSize: 10
		};		

		const labelOptionSalary = {
			position: 'left',
			distance: 10,
			rotate: 90,
			show: true,
			formatter: '{a}',
			fontSize: 14
		};

		const labelOptionCosts = {
			position: 'right',
			distance: 10,
			rotate: 90,
			show: true,
			formatter: '{a}',
			fontSize: 14
		};		
		
		if (income) {
			const salaryRef = reference.income.salary[0].period == "monthly" ? reference.income.salary[0].average : 
				Math.round(reference.income.salary[0].average/12);
			dataset = [...dataset,{
					name: 'Salary',
					data: [ salaryRef , Math.round(income['salary']/12)],
					stack: 'Salary',
					type: 'bar',
					barWidth: 8,
					label: labelOptionSalary
				}];
		}

		reference && Object.keys(reference.expenditure.costs).map((key,i) => {
			dataset = [...dataset,{
				name: key,
				data: [reference.expenditure.costs[key],key=="Rental Fee" ? scenarioList.current[id].matrix[0].properties['price'] : reference.expenditure.costs[key]],
			    stack: 'Reference',
			    type: 'bar',
				label: labelOptions
			  }
			];
			labels = [...labels,key];
			costRef += reference.expenditure.costs[key];
			costMy += key=="Rental Fee" ? Number(scenarioList.current[id].matrix[0].properties['price']) : Number(reference.expenditure.costs[key]);
			colors = [...colors, 'rgba('+(13/paramLength*i)+','+(110/paramLength*i)+','+(253/paramLength*i)+' ,0.7)'];
			}
		);
		
		if (income) {
			const financialBalance = Math.round(income['salary']/12 - costMy);
			const salaryRef = reference.income.salary[0].period == "monthly" ? reference.income.salary[0].average : 
				Math.round(reference.income.salary[0].average/12);
			dataset = [...dataset,{
					name: 'Savings' ,
					data: [ salaryRef -costRef, financialBalance],
					stack: 'Reference',
					type: 'bar',
					label: labelOptions
				}];
		}
			
			dataset = [...dataset,{
				name: 'Costs',
				data: [ costRef , costMy],
				stack: 'Costs',
				type: 'bar',
				barWidth: 8,
				label: labelOptionCosts
			}];
			labels = [...labels,'Savings','Costs'];
			colors = [...colors, 'rgba(13,110,253,0.7)','rgba(200,0,0,0.7)'];

		const option = {
			aria: {
				show: true
			},
			title: {
				text: 'Costs against salary',
				x: 'center',
			   textStyle: {
				fontWeight: 'bold',
				fontSize: 14,
				lineHeight: 20
			  },  
			},

			tooltip: {},
				  legend: {
					left: 'center',
					top: 'bottom',
					data: dataset.map((param) => param.name)
				  },
			emphasis: {
				focus: 'series',
				blurScope: 'coordinateSystem'
			},
			color: colors,
			xAxis: {
				type: 'category',
				data: ['Others in '+income['city'],'You']
			  },
			yAxis: {
				show: false ,
			  },
			  series: dataset,
			  maintainAspectRatio: false
		}; 	
		
		const eventsMap = {
			legendselectchanged: function(params) {
			  console.log('legendselectchanged',params)
			  // handle selection: 1) store in cookies, 2) read this cookie when request sent
			},
		}

		return (
			<ReactEcharts 
			ref={thisRef}
			style={{minHeight: '30vh', width: isMobile? '100vw' : '50vw'}}
			onEvents={eventsMap}
			opts={{renderer: 'svg'}}  
			option={option} />
		);
	}

	const AffordabilityCalculator = ({param}) => {
		console.log('AffordabilityCalculator',scenarioList.current, id, );
		const [path, setPath] = useState(false);


		return (
		<Row xs={1} md={2}>
			<Col xs={12} md={6} style={{margin: 'auto',display: 'flex',padding: '0px'}}>
				{scenarioList.current[id].reference &&  <AffordabilityChart path={path} reference={scenarioList.current[id].reference} income={scenarioList.current[id].matrix[1].properties} costs={scenarioList.current[id].matrix[0].properties}/>}
			</Col>
			<Col xs={12} md={6}>
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}} >
					{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.affordability.map((item,i) => <li key={"affordability"+i}>{item}</li>)}
				</ul>			
			</Col>

		</Row>
		);
	}

	const RecognitionCalculator = ({param}) => {
		const ratio = scenarioList.current[id].scores && scenarioList.current[id].scores[param]*10;
		const industry = scenarioList.current[id].matrix[1].properties.industry;

		return (
		<Row xs={1} md={2} style={{minHeight: '30vh'}}>
			<Col xs={12} md={6} style={{margin: 'auto',display: 'flex',padding: '0px'}}>
				{scenarioList.current[id].reference && <RecognitionChart reference={scenarioList.current[id].reference} personalIncome={scenarioList.current[id].matrix[1].properties} costs={scenarioList.current[id].matrix[0].properties}/>}
			</Col>
			<Col xs={12} md={6} >
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}}>
					{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.recognition.map((item,i) => <li key={i}>{item}</li>)}
				</ul>			
			</Col>

		</Row>
		);
	}

	const QualityChart = ({reference}) => {
		console.log('QualityChart');

		const option = {
				tooltip: {},
				  legend: {
					left: 'center',
					top: 'bottom',
					data: reference.map((param) => param.name)
				  },
			  series: [
				{
				  type: 'pie',
				  radius: [10, 100],
				  center: ['50%', '50%'],
				  roseType: 'area',
				  itemStyle: {
					borderRadius: 5
				  },
				label: {
				  show: false,
				  position: 'middle',
				  formatter: '{b}'
				},
				  data: reference
				}
			  ]
			};
/*
Quality of life:
	- Work-life balance
	- green area
	- Entertainment (restaurants, bars)
	- Security?
	- Air quality?
	- Shopping
	- Sports opportunity
	- Distance from inner city
	
Location quality based on:
	- price of restaurants
*/

		return (
			<ReactEcharts 
			style={{minHeight: '30vh', width: isMobile? '100vw' : '50vw'}}
			opts={{renderer: 'svg'}}  
			option={option} />
		);
	}
	
	
	const QualityCalculator = ({param}) => {

			const qualityEvaluation = [
					{ value: 2, name: 'Work-life balance' },
					{ value: 1.2, name: 'Entertainment' },
					{ value: 2.4, name: 'Green' },
					{ value: 3.6, name: 'Shopping' },
					{ value: 4.4, name: 'Sports opportunity' },
					{ value: 7.3, name: 'City proximity'}
				  ];
		return (
		<Row xs={1} md={2} style={{minHeight: '30vh'}}>
			<Col xs={12} md={6} style={{margin: 'auto',display: 'flex',padding: '0px'}}>
				{scenarioList.current[id].reference && <QualityChart reference={scenarioList.current[id].evaluation.qualityoflife.scores} />}
			</Col>
			<Col xs={12} md={6} >
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}}>
					{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.qualityoflife.desc.map((item,i) => <li key={i}>{item}</li>)}
				</ul>			
			</Col>

		</Row>
		);
	}
	
	const CommuteCalculator = ({param}) => {
		console.log('CommuteCalculator',param,id,scenarioList.current[id]);
		const thisRef = useRef();
		const medalsRef = useRef();
		const lineStyle = "path-3+22f-1.8";
		const polygonURI = polyline.encode(scenarioList.current[id].matrix.map(item => item && [item.geometry.coordinates[1],item.geometry.coordinates[0]]));
		const [path, setPath] = useState();
		const [mapContent, setMapContent] = useState();
		const lineCoords = scenarioList.current[id].matrix.map(item => [item.geometry.coordinates[0],item.geometry.coordinates[1]]);
		const [mode, setMode] = useState(0);

		useEffect(() => {

			const requestOptions = {
				method: 'POST',
				headers: { 
					'Host': 'routespreferred.googleapis.com',
					'Content-Type': 'application/json',
					'X-Goog-Api-Key': 'AIzaSyDenC1k5H6mYqbnFnn87qd-p2MqZQc4Wn0',
					'X-Goog-FieldMask': 'routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline'
					},
				body: JSON.stringify({
							  "origin":{
								"location":{
								  "latLng":{
									"latitude": 37.419734,
									"longitude": -122.0827784
								  }
								}
							  },
							  "destination":{
								"location":{
								  "latLng":{
									"latitude": 37.417670,
									"longitude": -122.079595
								  }
								}
							  },
							  "travelMode": "DRIVE",
							  "routingPreference": "TRAFFIC_AWARE",
							  "polylineQuality": "OVERVIEW",
							  "departureTime": "2019-10-15T15:01:23.045123456Z",
							  "computeAlternativeRoutes": false,
							  "routeModifiers": {
								"avoidTolls": false,
								"avoidHighways": false,
								"avoidFerries": false
							  },
							  "languageCode": "en-US",
							  "units": "IMPERIAL"
							})
					};
					
			const x = 'https://maps.googleapis.com/maps/api/directions/json?origin=place_id:ChIJ685WIFYViEgRHlHvBbiD5nE&destination=place_id:ChIJA01I-8YVhkgRGJb0fW4UX7Y&key=AIzaSyDenC1k5H6mYqbnFnn87qd-p2MqZQc4Wn0';		
			console.log('googleAPI request', "https://api.mapbox.com/directions/v5/mapbox/driving-traffic/"+lineCoords[0].join()+";"+lineCoords[1].join()+"?depart_at=2024-06-21T08:00&access_token="+mapboxgl.accessToken);

			const travelMode = {
				0: 'walking/',
				1: 'cycling/',
				2: "driving-traffic/"
			}[mode];

			fetch("https://api.mapbox.com/directions/v5/mapbox/"+travelMode+lineCoords[0].join()+";"+lineCoords[1].join()+"?depart_at=2024-06-21T08:00&access_token="+mapboxgl.accessToken)
			//fetch(x,{mode: 'no-cors'})
			//fetch('https://routespreferred.googleapis.com/v2:computeRoutes/mode:no-cors/',requestOptions)
				.then(checkStatus)  // check the response of our APIs
				.then(response => response.json())
				.then(usefulData => {

					console.log('Direction JSON', usefulData);
					setPath(usefulData);

				})
				.catch(e => console.log(`Error happenned when loading: ${e}`));
		},[,mode]);
		
		//className={expand ? "noShow":"col-6"}
		//className={expand ? "col-12":"col-6"}
		
		function resizeChart(e) {
			console.log('resize CommuteCalculator',e);
			!mapContent && scenarioList.current[id].reference && setMapContent( <CommuteChart mode={mode?mode:0} nodeFit={thisRef.current} path={path ? lineStyle+"("+path.routes[0].geometry+")" : lineStyle+"("+polygonURI+")"} />);
		}

	
		function pressedmedal(l, e) {
			[...medalsRef.current.childNodes].map((cName,i) => cName.className = i==l ? "compareMedalPressed" : "compareMedal");
			setMode(l);
		}			
		
		return (
		<Row xs={1} md={2} ref={thisRef} style={{minHeight: '30vh'}}>

			<Col xs={12} md={6} ref={(e) => resizeChart(e)} style={{margin: 'auto',display: 'flex',padding: '0px', position: 'relative'}}>
				<div style={{position: 'absolute', right: '0'}} ref={medalsRef}>
					<div className={"compareMedalPressed"} onClick={(e) => pressedmedal(0,e)} >
						<IoIcon.IoIosWalk   />
					</div>
					<div className={"compareMedal disabled"}  >
						<IoIcon.IoMdBicycle />
					</div>
					<div className={"compareMedal"} onClick={(e) => pressedmedal(2,e)}  >
						<FaIcon.FaCar />
					</div>
					<div className={"compareMedal disabled"}   >
						<FaIcon.FaBus />
					</div>
				</div>

				{mapContent}
			</Col>
			<Col xs={12} md={6} >
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}}>
				{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.commute.map((item,i) => <li key={i}>{i==0 && path && Math.round(path.routes[0].duration/60)*2 }  { item}</li>)}
				</ul>			
			</Col>
		</Row>

		);
	}

	const EvalFrame = forwardRef(({children, currentTab},thisRef) => {
		const [expand, setExpand] = useState(false);
		function handleClick () {
			setExpand(c=>!c);
		}
		
		useEffect(() => {
			currentTab&&currentTab.current.refresh();
		},[expand]);		
		
		return (
		<Row ref={thisRef} xs={2} style={{minHeight: '30vh'}}>
			<div className={expand ? "noShow":"col-6"} >
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}}>
				{children[0]}
				</ul>			
			</div>
			<div className={expand ? "col-12":"col-6"} style={{margin: 'auto',display: 'flex',padding: '0px'}}>
				<div className="expandArrow" onClick={handleClick}>
					{{true:<Icon.BsFillArrowRightCircleFill />,
					false:<Icon.BsFillArrowLeftCircleFill />
						}[expand]}
				</div>
				<div>
					{children[1]}
				</div>
			</div>
		</Row>
		
		);
	});

	const SecurityCalculator = ({param}) => {

		return (
		<Row xs={1} md={2}>
			<Col xs={12} md={6} style={{margin: 'auto',display: 'flex',padding: '0px'}}>
				{scenarioList.current[id].reference &&  <SecurityChart reference={scenarioList.current[id].security} />}
			</Col>
			<Col xs={12} md={6}>
				<ul className="picBox" style={{margin: '0px', paddingTop: '10px'}} >
					{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.security.map((item,i) => <li key={i}>{item}</li>)}
				</ul>			
			</Col>

		</Row>
		);
	}
	
	const LocationCalculator = ({param}) => {

		return (
		<Row xs={2}>
			<Col xs={6}>
			<ul style={{margin: '0px', paddingTop: '10px'}}>
				{scenarioList.current[id].evaluation && scenarioList.current[id].evaluation.location.map((item,i) => <li key={i}>{item}</li>)}
			</ul>			
			</Col>
		</Row>
		);
	}
	
	
/*
	isMobile&&window.screen.orientation.type.includes('portrait') ? 
		<Tabs
		  defaultActiveKey={0}
		  id="fill-tab-example"
		  className="mb-3"
		  fill
		>
		{ categories.filter((_,i) => i>0 ).map((key, index) => 
		  <Tab eventKey={index} title={key.value} key={index}>
						{{
							0: <AffordabilityCalculator param={key.value}/>,
							1: <RecognitionCalculator param={key.value}/>,
							2: <QualityCalculator param={key.value}/>,
							3: <CommuteCalculator param={key.value}/>,

						}[index]}
		  </Tab>
		)}

		</Tabs>
	:
*/

/*
							 <ProgressBar now={scenarioList.current[id].scores[key]*10} label={`${scenarioList.current[id].scores[key]}`} key={index+"bar"} style={{margin: 0, height: shrink ? '2px' : '10px'}} />

*/

	return (
	<Tab.Container id="left-tabs-example" defaultActiveKey={0}>
	{!loadingComp &&
	<>
			<div className="sectionDivider"></div>
	<Row xs={2} md={1}>
		{ scenarioList.current[id] && scenarioList.current[id].scores && Object.keys(scenarioList.current[id].scores).filter((_,i) => i>0 ).map((key, index) => 
		<Col xs={4} md={2} className="" key={"pills"+index} style={{padding: '0px'}}>
			<Nav bg="light" variant="pills" className="features" >
				<Nav.Item className="">
					<Nav.Link eventKey={index} className="pillsItem pillsNav">
						<Pie percentage={scenarioList.current[id].scores[key]*10} colour={'blue'} lineWidth={"0.2rem"} testSize={'1em'} chartWidth={50}/>
						<p>{!shrink && key}</p>
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</Col >
		)}
	</Row>
			<div className="sectionDivider"></div>

	<Row xs={1}>
		<Col xs={12} >
			<Tab.Content>
				{ scenarioList.current[id] && scenarioList.current[id].scores && Object.keys(scenarioList.current[id].scores).filter((_,i) => i>0).map((key, index) => 
					<Tab.Pane eventKey={index} key={index} mountOnEnter={true} unmountOnExit={true}>
						{{
							"Affordability": <AffordabilityCalculator />,
							"Recognition": <RecognitionCalculator />,
							"Quality of life": <QualityCalculator />,
							"Commute Time": <CommuteCalculator />, 
							"Security": <SecurityCalculator />, 
							"Location": <LocationCalculator />, 

						}[key]}
					</Tab.Pane>
					)
				}
			</Tab.Content>
		</Col >
	</Row>
	</>
	}

</Tab.Container>
	
	);
}



const InsertRentalAccordion = (props) => {

  return (
	<Col key={props.rental.properties.id} id={props.rental.properties.id} className="rentalCardClass" >
		<Accordion.Item eventKey={props.index} >
			<Accordion.Header>
				<div style={{ width: "50px", padding: "0"}} >
					<Image src={correctImgAddress(props.rental.properties.image)}  thumbnail    />
				</div>	
				<div >
					<div  style={{fontSize: "0.7rem", margin: "auto", padding: "0 10px 0 10px", fontWeight: "bold"}}>{props.rental.properties.currency} <span style={{fontSize: "1rem"}}>{props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>, {props.rental.properties.bed} bed apartment</div>
					<div style={{fontSize: "0.7rem", margin: "auto", padding: "0 10px 0 10px"}}>in <span>{props.rental.properties.area}</span>, {props.rental.properties.city}</div>
				</div>	
			</Accordion.Header>
			<Accordion.Body>
				<Row xs={2}>
					<Col style={{overflow: "hidden"}}>
						<Image className="RentalCardImg" src={correctImgAddress(props.rental.properties.image)}   />
					</Col >
					<Col >
						<InsertParam name="Price" value={props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>
						{props.rental.properties.currency != "USD" && <InsertParam name="" value={props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.rental.properties.currency}/>}

						{props.rental.properties.size >0 && (<InsertParam name="Size" value={props.rental.properties.size&&Math.trunc(props.rental.properties.size).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.7} unit="sqm" />)}
						<InsertParam name="Bathrooms" value={props.rental.properties.bath} />
						<InsertParam name="ID" value={props.rental.properties.id} />
						{props.rental.properties.pets && <FaIcon.FaDog/>}
						{props.rental.properties.balcony && <FaIcon.FaParking/>}
						{props.rental.properties.garage && <FaIcon.FaParking/>}
					</Col >
				</Row>
			</Accordion.Body>
		</Accordion.Item>
	</Col>
	);

};

const InsertJobAccordion = (props) => {

	return (
		<Col key={props.job.properties.id} id={props.job.properties.id} className="rentalCardClass" >
			<Accordion.Item eventKey={props.index} >
				<Accordion.Header>
					<div style={{ width: "50px", padding: "0"}} >
						<Image src={props.job.properties.icon}  width="30" height="30" />
					</div>	
					<div >
						<div  style={{fontSize: "0.7rem", margin: "auto", padding: "0 10px 0 10px", fontWeight: "bold"}}>{props.job.properties.name} at <span>{props.job.properties.company}</span></div>
						<div style={{fontSize: "0.7rem", margin: "auto", padding: "0 10px 0 10px"}}> in {props.job.properties.city}</div>
					</div>	
				</Accordion.Header>
				<Accordion.Body>
					<Row xs={2}>
						{props.job.properties.salary&&<InsertParam name="Salary" value={props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>}
						{props.job.properties.currency != "USD" && <InsertParam name="" value={props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.job.currency}/>}
						{props.job.properties.sector && <InsertParam name="Sector" value={props.job.properties.sector} />}
						{props.job.properties.industry && <InsertParam name="Industry" value={props.job.properties.industry} />}
						{props.job.properties.city&&<InsertParam name="City" value={props.job.properties.city} />}
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</Col>
	);
};


//props.navs &&(<AddPinPlan type={"rental"} id={props.rental.properties.id} />)


	const LikedModal = forwardRef(({likedRentalList,likedJobList}, ref) => {
		const [showLikedModal, setShowLikedModal] = useState(false);
		const [isListEmpty, setIsListEmpty] = useState(true);	

		const likedRentalElements = initRentalsGEOJson.current.filter((item,i) => rentalPlan.current.includes(item.properties.id) );
		const likedJobElements = initJobsGEOJson.current.filter((item,i) => jobPlan.current.includes(item.properties.id) );

		useEffect(() => {
		   !showLikedModal && setIsListEmpty(rentalPlan.current.length>0 || jobPlan.current.length>0 || schoolPlan.current.length>0 ? false:true);
		},[showLikedModal]);

		  useImperativeHandle(ref, () => {
			return {
			  markEmpty(isEmpty) { setIsListEmpty(isEmpty)}
			};
		  }, []);

		return (
		<>
			<Icon.BsHeart onClick={() => {setShowLikedModal(true)}}/>
			<sub>{!isListEmpty&&<Badge pill bg="danger" style={{color: 'red', width: '10px', height: '10px', position: 'absolute'}}>.</Badge>}</sub>

		  <Modal show={showLikedModal} 
		  fullscreen={true} 
		  onHide={() => setShowLikedModal(false)}
		  size="lg"
		  aria-labelledby="contained-modal-title-vcenter"
		  centered

		  >
			<Modal.Header closeButton />
			<Modal.Body >

			  <RentalCards type={'Rentals'} rentals={rentalPlan.current} navs={true} condensed={true} />
			  <RentalCards type={'Jobs'} rentals={jobPlan.current}  navs={true} condensed={true} />

			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		  </Modal>
		</>
		  );
	});

const SetNavbar = ({isEmptyRef, compareModalRef}) => {
	//console.log('SetNavbar started');
	const [bottomMenu,setBottomMenu] = useState(false);
	const handleBottomChange = () => setBottomMenu(!bottomMenu );



	
	const OffCanvasMenu = () => {
		const [offCanvasShow, setOffCanvasShow] = useState(false);
		const [darkMode,setDarkMode] = useState(false );
		console.log('OffCanvasMenu',darkMode);
		const handleStyleChange = () => setDarkMode(!darkMode);

		useEffect(() => {
		   //if (map.current) {darkMode ? map.current.setStyle('mapbox://styles/mapbox/dark-v10') : map.current.setStyle('mapbox://styles/mapbox/light-v10')};
		},[darkMode]);
		  
		return (
		<>
			<AiIcon.AiOutlineMenu onClick={() => {setOffCanvasShow(true)}}/>
			<Offcanvas show={offCanvasShow} scroll={true} onHide={() => setOffCanvasShow(false)} placement={'end'} name={'end'} style={{zIndex: 10000}}>
				<Offcanvas.Header closeButton />
				<Offcanvas.Body style={{margin: ''}}>
				<Container>
					<Row style={{padding: '20px'}}>
						<Col xs={8} style={{margin: 'auto'}}>
							<Icon.BsPersonCircle style={{color: 'grey',width: '50px',height: '50px'}} />
						</Col>
						<Col xs={4} style={{margin: 'auto'}}>
							Username
						</Col>
					</Row>
					<Row style={{padding: '20px'}}>
						<Col xs={8} style={{margin: 'auto'}}>
							Dark mode
						</Col>
						<Col xs={4} style={{margin: 'auto'}}>
							<Container className="">
							  <Form>
								<Form.Check
								  type="switch"
								  id="darkMode-switch"
								  checked={darkMode}
								  onChange={handleStyleChange}
								/>
							  </Form>
							</Container>


						</Col>
					</Row>
					{/*<Row style={{padding: '20px'}}>
						<Col xs={8} style={{margin: 'auto'}}>
							Bottom menu
						</Col>
						<Col xs={4} style={{margin: 'auto'}}>
							<Container className="">
							  <Form>
								<Form.Check
								  type="switch"
								  id="bottomMenu-switch"
								  checked={bottomMenu}
								  onChange={handleBottomChange}
								/>
							  </Form>
							</Container>


						</Col>
					</Row>*/}
					</Container>
				</Offcanvas.Body>
			</Offcanvas>
		</>
		);
	}

/*
			  <div className="stepButton" >
				<LikedModal ref={isEmptyRef}/>
			  </div>
*/

  return (
	<>
		<Navbar fixed={!window.screen.orientation.type.includes('portrait') ? (bottomMenu ? "bottom" : "top") : ""} collapseOnSelect expand="lg" bg="light" variant="light" style={{height: "-webkit-fill-available", padding: '0'}}>
		  <Container className={"mainNav"} >
			<Stack gap={3} >
			  <div className="stepButton"  >
				<InitModal />
			  </div>

			  <div className="stepButton" >
				  <CompareModal isEmptyRef={isEmptyRef} ref={compareModalRef}/>
			  </div>
			  <div className="stepButton" >
				<OffCanvasMenu />
			  </div>
			</Stack>
		  </Container>
		</Navbar>
	</>
  );
}

	const CompareModal = forwardRef(({isEmptyRef},ref) => {
		console.log('CompareModal');

		const [showCompareModal, setShowCompareModal] = useState(false);
		const [isValidScenario, setIsValidScenario] = useState(false);
		const categories = ['Overall Score','Affordability','Recognition','Quality of life','Commute Time','Security','Location',].map(
		  item => ({label: item, value: item}));

			createScenarios(categories);

			function handleOpenModal(e){
				e.stopPropagation();
				setShowCompareModal(true);
			}

		  useImperativeHandle(ref, () => {
			return {
			  markEmpty(isEmpty) { setIsValidScenario(isEmpty)}
			};
		  }, []);

		return (
		<>
			<div className={isValidScenario&&"withDot"}>
				<Icon.BsSend style={{fontSize: '1.3rem'}} onClick={handleOpenModal}/> 
			</div>
		  <Modal 
			  show={showCompareModal} 
			  onHide={() => setShowCompareModal(false)}
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  size="lg"
			  fullscreen={'md-down'} 
			  contentClassName="modalContent"
			  dialogClassName="modalDialog"
			  >
					<ComparePlans scenarios={scenarioList.current} categories={categories} />
		  </Modal>
		</>  
		);
	});


		const ComparePlans = ({scenarios, categories}) => {
			console.log('ComparePlans',scenarios, categories,scenarioList.current[0].matrix.indexOf(false));

			function sortScenarios(paramA,paramB) {
				console.log('sortScenarios',scenarios);

				scenarios&&scenarios.sort((a,b) => a.scores&&b.scores ? (paramA? (a.scores[paramB] > b.scores[paramB] ? 1 : -1) : (a.scores[paramB] < b.scores[paramB] ? 1 : -1)) : -10);
				return ;
			}			
		
			const [sortBy, setSortBy] = useState(categories[0]);
			const [sortdir, setSortdir] = useState(false);
			const [radar, setRadar] = useState(false);
			
			const offCanvDir = window.innerWidth<window.innerHeight ? 'bottom':'start' ;
			const rsp = 'lg';

			function handleSortByOnChange(e) {
				sortScenarios(sortdir, e.value);
				setSortBy(e.value);
			}

			function handleSortDirOnChange(e) {
				sortScenarios(e.currentTarget.checked,sortBy);
				setSortdir(e.currentTarget.checked);
			}

			function handleRadarOnChange(e) {
				setRadar(e.currentTarget.checked);
			}

			function handleScroll(e) {

				
			}			
									//<InputGroup.Text id="inputGroup-sizing-sm">Sort by</InputGroup.Text>

			return (
			<>
				<Modal.Header closeButton >
					<Container fixed="top">
						<Row xs={2} className="mb-2">
							<Col xs={8} >
								<InputGroup size="sm" className="mb-2" style={{zIndex: 100}}>
									<Select 
										options={categories}
										onChange={handleSortByOnChange}
										defaultValue={sortBy }
									/>
								</InputGroup>
							</Col>
							<Col xs={2} >
								<ToggleButton
									className="mb-2"
									id="sortdir"
									type="checkbox"
									variant="outline-primary"
									checked={sortdir}
									value="1"
									onChange={handleSortDirOnChange}
								>
								{sortdir ? <Icon.BsSortDownAlt /> : <Icon.BsSortDown /> }
								</ToggleButton>
							</Col>
							<Col xs={2} >
								<ToggleButton
									className="mb-2"
									id="radar"
									type="checkbox"
									variant="outline-secondary"
									checked={radar}
									value="1"
									onChange={handleRadarOnChange}
								>
								<AiIcon.AiOutlineRadarChart /> 
								</ToggleButton>
							</Col>				
						</Row>
					</Container>
				</Modal.Header>
				
				<Modal.Body className="compareModalBody" >
				{/*<Swiper 
					slidesPerView={'auto'}
					centeredSlides={true}
					spaceBetween={30}
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination, Navigation]}
					className="mySwiper"
					style={{height: '100%'}}
					navigation={true}
					
					>
					{ scenarios && scenarios.map((scenario, idx) => 
							(scenario.matrix.indexOf(false) < 0 &&
								<SwiperSlide  key={"scenario"+idx}>
									<FetchPlans id={idx} categories={categories}/>
								</SwiperSlide>
					))}
				</Swiper>*/}
				<FetchPlans categories={categories}/>

				<Offcanvas show={radar} scroll={true} onHide={() => setRadar(false)} placement={offCanvDir} name={offCanvDir} style={{zIndex: 10000, width: '100%'}}>
							<Offcanvas.Header closeButton />
							<Offcanvas.Body style={{margin: 'auto'}}>
								<AddRadar categories={categories}/>
							</Offcanvas.Body>
						</Offcanvas>
				</Modal.Body>

			</>
			);
		}


const AddRadar = ({ categories}) => {
  const chartRef = useRef();
  const onClick = (event) => {
    console.log('AddRadar',getDatasetAtEvent(chartRef.current, event));
  }
	var labels = [];
	categories.filter((_,i) => i>0 ).map((key, index) => {
		labels = [...labels, key.value];
	});

	
	var data= [{
      label: '',
	  data: [0,0,0,0,0],
	  backgroundColor: 'rgba(255, 255,255, 0)',
	  borderColor: 'rgba(255, 255,255, 0)',
	  borderWidth: 0}];

	scenarios.map((scenario, idx) => {
		var dataItems = [];
		categories.filter((_,i) => i>0 ).map((key, index) => {
			dataItems = scenario.scores ? [...dataItems, scenario.scores[key.value]] : dataItems;
				 
			});

		data = [...data,     
				{
				  label: scenario.id[0],
				  data: dataItems,
				  backgroundColor: 'rgba(50, 50,' + idx*(150/scenarios.length)+100 +', 0.2)',
				  borderColor: 'rgba(50, 50,' + idx*(150/scenarios.length)+100 +', 1)',
				  borderWidth: 1}
				  ];
	});
	

	const radarData = {
	  labels: labels,
	  datasets: data
	};
	


	return (
    <Radar data={radarData} onClick={onClick} ref={chartRef}/> );
}


function checkStatus(response) {
	
 //console.log('checkStatus',response);
	if (response.ok) {
		return Promise.resolve(response);
	} else {
		return Promise.reject(new Error(response.statusText));
	}
}


const AddScenarioCard = ({id,categories}) => {

	return (
		<Row xs={3} >
		{Array.from({ length: 3 }).map((_,j) => 
				({
				0: scenarioList.current[id].matrix[j] ? <InsertScenarioRentalCard key={"RentalCard"+j} rental={scenarioList.current[id].matrix[j]} /> :<div key={j}> No rental </div>,
				1: scenarioList.current[id].matrix[j] ? <InsertScenarioJobCard key={"JobCard"+j} job={scenarioList.current[id].matrix[j]} />:<NoJob key={j}/>,
				2: scenarioList.current[id].matrix[j] ? <InsertSchoolCard key={"SchoolCard"+j} school={scenarioList.current[id].matrix[j]} />:<NoSchool key={j} school={scenarioList.current[id].matrix[j]} />
				}[j])
		)}
		</Row>
	);
}

const InsertScenarioRentalCard = (props) => {
	
	function handleShow() {
		return;
	}

	function handleClick(e) {
		//setActiveElement(props.rental);
	}

  return (
	<Col id={props.rental.properties.id} className="rentalCardClass" >
        <Card onClick={handleShow} className="compareScenCards" onClick={handleClick}>
		<Card.Img className="compareRentalCardImg" variant="top" fluid="true" src={correctImgAddress(props.rental.properties.image)} />
		<Card.Body style={{cursor: "pointer",position: "absolute",backgroundColor: "rgba(255,255,255,0.5)", bottom: "0px", width: "100%", padding: "0"}}>
			<InsertParam name="" value={props.rental.properties.bed+" bedroom flat"} /> 
			<InsertParam name="" value={"for "+props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>
			{props.rental.properties.currency != "USD" && <InsertParam name="" value={props.rental.properties.price&&Math.trunc(props.rental.properties.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.rental.properties.currency}/>}
		</Card.Body>
        </Card>
	</Col>
	);
}

const InsertScenarioJobCard = (props) => {
	
	function handleShow() {
		return;
	}
	function handleClick(e) {
		//setActiveElement(props.job);
	}
  return (
 	<Col id={props.job.properties.id} className="rentalCardClass" >
         <Card  onClick={handleShow} className="compareScenCards" onClick={handleClick}>

			<Card.Img variant="top" style={{margin: "inherit" }} fluid="true" src={props.job.properties.icon} />
			<Card.Body style={{cursor: "pointer",position: "absolute",backgroundColor: "rgba(255,255,255,0.5)", bottom: "0px", width: "100%", padding: "0"}}>
				<InsertParam name="" value={props.job.properties.name} /> 
				<InsertParam name="" value={"at "+props.job.properties.company} />

				{props.job.properties.salary && <InsertParam name="" value={props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.9} unit="USD"/>}
				{props.job.properties.currency && props.job.properties.currency != "USD" && <InsertParam name="" value={props.job.properties.salary&&props.job.properties.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} size={0.6} unit={props.job.properties.currency}/>}
            </Card.Body>
          </Card >
	</Col>
	);
}
	 	
const AddAccordions = ({id,categories}) => {

	return (
		<>
		{Array.from({ length: 3 }).map((_,j) => 
			<Accordion alwaysOpen key={j}>
				{({
				0: scenarioList.current[id].matrix[j] ? <InsertRentalAccordion rental={scenarioList.current[id].matrix[j]} index={j} navs={false} stage={'compare'} />:<div> No rental </div>,
				1: scenarioList.current[id].matrix[j] ? <InsertJobAccordion job={scenarioList.current[id].matrix[j]} navs={false} index={j} stage={'compare'} />:<NoJob />,
				2: scenarioList.current[id].matrix[j] ? <InsertSchoolCard school={scenarioList.current[id].matrix[j]} navs={false} />:<NoSchool school={scenarioList.current[id].matrix[j]} />
				}[j])}
			</Accordion>
		)}
		</>
	);
}

const FetchPlans = ({id,categories}) => {
	console.log('FetchPlans',scenarioList.current, categories);
	const [shrink, setShrink] = useState(false);
	const [mouseDown, setMouseDown] = useState(false);
	const currentPos = useRef(1000000);
	
	function handleMouseDown(e) {
		e.stopPropagation();
		e.preventDefault();
		console.log('FetchPlans handleMouseDown');
		e.target.style.cursor='grabbing';
		setMouseDown(true);
	}

	function handleMouseUp(e) {
		e.stopPropagation();
		e.preventDefault();
		console.log('FetchPlans handleMouseUp');
		e.target.style.cursor='grab';

		setMouseDown(false);
	}	

	function handleClick(e) {
		console.log('FetchPlans handleClick',e);

	}

	function handleDragStart(e) {
		console.log('FetchPlans handleDragStart',e);

	}
	
	function handleScrl(e) {
		console.log('FetchPlans handleScrl',
			e.target.getBoundingClientRect().top, 
			e.target.childNodes[0].getBoundingClientRect().top, 
		);
			
		if (currentPos.current < e.target.childNodes[0].getBoundingClientRect().top) {
				setShrink(true);
		} else {
				//setShrink(false);				
		}
		currentPos.current = e.target.childNodes[0].getBoundingClientRect().top;
	}


/*
		scenarioList.current[id] &&
		<Card className="compareCard" >
			<Card.Header style={{fontWeight: "bold"}}>
				<AddPie id={id} categories={categories}/>
			</Card.Header>
			<Card.Body style={{height: (window.innerHeight*0.8-2*30)+'px', overflowY: 'scroll'}} onDragStart={handleDragStart} >
				<AddScenarioCard id={id} categories={categories}/>
				<AddScenEvaluation id={id} categories={categories} shrink={shrink}/>
			</Card.Body>
			<Card.Footer className="text-muted" onDragOver={handleScrl} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
				<Button className="justify-content-md-center"  variant="primary" type="submit" as={Col} xs={12} md={4} onClick={handleClick}>
						Book it now
				</Button>	  
			</Card.Footer>
			
			
		</Card>
*/
	
	return ( 
			<Accordion alwaysOpen>
		{ scenarioList && scenarioList.current.map((scenario, idx) => 
			scenario.matrix.indexOf(false) < 0 &&
				<Accordion.Item key={"scen"+idx} eventKey={idx} className="compareCard" >
					<Accordion.Header style={{fontWeight: "bold", width: '100%'}}>
						<AddPie id={idx} categories={categories}/>
					</Accordion.Header>
					<Accordion.Body style={{height: (window.innerHeight*0.8-2*30)+'px', overflowY: 'scroll'}} onDragStart={handleDragStart} >
						<AddScenarioCard id={idx} categories={categories}/>
						<AddScenEvaluation id={idx} categories={categories} shrink={shrink}/>
					</Accordion.Body>
				</Accordion.Item>
		)}
			</Accordion >
	);
}

	const Medals = ({medals}) => {
		const [medalPos, setMedalPos] = useState(map.current&&map.current._controls[2]._container.getBoundingClientRect());
		
		function handleResize() {
			console.log('handleResize');
			setMedalPos(map.current&&map.current._controls[2]._container.getBoundingClientRect());
		}
		
		useEffect(() => {
			window.addEventListener('resize', handleResize);

		},[]);

		return (
		<div
			style={{display: 'block', position: 'absolute',
				top: map.current&&medalPos.bottom+10,
				left: map.current&&medalPos.left
			}}
		>
			<CompareMedal ref={medals}/>
		</div>
		);
	}

	const Medal3D = forwardRef(({},ref) => {
		const [showMedal, setShowMedal] = useState(false);
		const [compareElements, setCompareElements] = useState([]);

		useEffect(() => {
			compareElements.length > 0 ? 
				medals.current.openMedal() : 
				medals.current.hideMedal();
		},[compareElements]);

		useImperativeHandle(ref, () => {
			return {
				openMedal(e) { 
					setShowMedal(true);
					},
				hideMedal() {setShowMedal(false)},
				addElem(elem) {
					console.log('addElem', elem.properties.city, compareElements);
					compareElements.includes(elem.properties.city) ? 
					medals.current.removeElem(elem.properties.city) :
					setCompareElements(c=> c.includes(elem.properties.city) ? 
						c.filter(x=> !x.includes(elem.properties.city)) : 
						[...c,elem.properties.city]);
					},
				removeElem(elem) {
					console.log('removeElem', elem.properties.city, compareElements);
					setCompareElements(compareElements.filter(x=> !x.includes(elem.properties.city)));
					}
			};
		}, []);
		return (
		<div
			onClick={(e) => compareCityModal.current.openModal(compareElements)}
			className={"compareMedal"}
				>3D</div>
		);
	});

	const CompareMedal = forwardRef(({},ref) => {
		const [showMedal, setShowMedal] = useState(false);
		const [compareElements, setCompareElements] = useState([]);

		useEffect(() => {
			console.log('compareElements', compareElements);
			compareElements.length > 0 ? 
				medals.current.openMedal() : 
				medals.current.hideMedal();
		},[compareElements]);

		useImperativeHandle(ref, () => {
			return {
				openMedal(e) { 
					setShowMedal(true);
					},
				hideMedal() {setShowMedal(false)},
				addElem(elem) {
					console.log('addElem', elem.properties.city, compareElements);
					compareElements.includes(elem.properties.city) ? 
					medals.current.removeElem(elem.properties.city) :
					setCompareElements(c=> c.includes(elem.properties.city) ? 
						c.filter(x=> !x.includes(elem.properties.city)) : 
						[...c,elem.properties.city]);
					},
				removeElem(elem) {
					console.log('removeElem', elem.properties.city, compareElements);
					setCompareElements(compareElements.filter(x=> !x.includes(elem.properties.city)));
					}
			};
		}, []);
		return (
		<div
			onClick={(e) => compareCityModal.current.openModal(compareElements)}
			className={"compareMedal "+"withDot"}
			style={{display: showMedal ? 'block' : 'none'
			}}>
			<TbIcon.TbGitCompare />
		</div>
		);
	});


	const OwnPopup = forwardRef(({},ref) => {
		console.log('OwnPopup');

		const [showPopup, setShowPopup] = useState(false);
		const [leftEdge, setLeftEdge] = useState(0);
		const popupBbox = useRef();
		const coordinates = useRef();
		const categories = ['Overall Score','Affordability','Recognition','Quality of life','Commute Time','Security','Location',].map(
		  item => ({label: item, value: item}));


		function handleOpenModal(e){
			e.stopPropagation();
			setShowPopup(false);
		}

		function calcTop(){
			return coordinates.current ? Math.round(coordinates.current.y+10)+"px" : 0;
		}

		function calcLeft(){
			if (!popupBbox.current) return;
			console.log('calcLeft',coordinates.current.x, popupBbox.current.getBoundingClientRect() , window.innerWidth, coordinates.current.x + popupBbox.current.getBoundingClientRect().width > window.innerWidth);
			
			setLeftEdge( coordinates.current.x + popupBbox.current.getBoundingClientRect().width > window.innerWidth ?
				window.innerWidth - popupBbox.current.getBoundingClientRect().width :
				coordinates.current.x );
			return;

			//return isMobile ? '5vw' : coordinates.current ? Math.round(coordinates.current.x)+"px" : 0;
		}
		
		useImperativeHandle(ref, () => {
			return {
				openPopup(e) { 
					coordinates.current = e.point;
					setShowPopup(true);
					},
				closePopup() {setShowPopup(false)}
			};
		}, []);
		
		function handleClick(e) {
			const features = map.current&&map.current.queryRenderedFeatures(coordinates.current, {layers: ['globalClusters']});
			medals.current.addElem(features[0]);
			cityPropsPopup.current.closePopup();
		}

		return (
			<Fade in={showPopup} timeout={2000} onEnter={() => calcLeft()}>
				<Button 
					onClick={handleClick}
					ref={popupBbox}
					className="popupStyle"
					variant="primary"
					style={{display: showPopup ? 'block' : 'none', 
							top: calcTop(),
							left: leftEdge
							}}>
								Add to compare
				</Button>
			</Fade>
		);
	});

	const CompareCityModal = forwardRef(({},ref) => {
		console.log('CompareCityModal');

		const [showCompareModal, setShowCompareModal] = useState(false);
		const listElements = useRef([]);
		const categories = ['Overall Score','Affordability','Recognition','Quality of life','Commute Time','Security','Location',].map(
		  item => ({label: item, value: item}));

		//const features = map.current&&map.current.queryRenderedFeatures(coords.point, {layers: ['globalClusters']});

		function handleOpenModal(e){
			e.stopPropagation();
			setShowCompareModal(true);
		}

		useImperativeHandle(ref, () => {
			return {
			  openModal(compareElements) { 
					console.log('openModal',compareElements, listElements.current);
				  listElements.current = compareElements;
				  //listElements.current = compareElements ? [...listElements.current,...compareElements] : listElements.current;
				  //listElements.current.filter((item,i) => listElements.current.indexOf(item) == i);
				  setShowCompareModal(true);
			  }
			};
		}, []);


		const CoLChart = ({reference, tableSize}) => {
					var dataset = [];
					var costRef = 0;
					var costMy = 0;
					var labels = [];
					var colors = ['#0d6efd']; //#0d6efd, #e9ecef
					const paramLength = Object.keys(reference[0].value).length;
					const labelOptions = {
						position: 'inside',
						show: true,
						formatter: '{a}',
						fontSize: 10
						};		

					const labelOptionSalary = {
						position: 'left',
						distance: 10,
						rotate: 90,
						show: true,
						fontSize: 14
						};
					// Number(value).toLocaleString('en');

					dataset = [...dataset,{
							name: 'Total Cost',
							data: reference.map(elem => Object.keys(elem.value).reduce((a,v) => a=a+elem.value[v],0)),
							stack: 'Cost',
							type: 'bar',
							barWidth: 1,
							label: labelOptionSalary
						}];

					Object.keys(reference[0].value).map((key,i) => {
						dataset = [...dataset,{
							name: key,
							data: reference.map((cityParams) => cityParams.value[key]),
							stack: 'Reference',
							type: 'bar',
							label: labelOptions
							}];
						labels = [...labels,key];
						colors = [...colors, 'rgba('+(13/paramLength*i)+','+(110/paramLength*i)+','+(253/paramLength*i)+' ,0.7)'];
						}
					);

					const option = {
						aria: {
							show: true
						},
						tooltip: {},
						emphasis: {
							focus: 'series',
							blurScope: 'coordinateSystem'
						},
						color: colors,
						xAxis: {
							type: 'category',
							data: reference.map((cityParams) => cityParams.key)//reference.map((cityParams) => Object.keys(cityParams.value).reduce((a,v) => a=a+cityParams.value[v] ,0))
						  },
						yAxis: {
							show: false ,
						  },
						  series: dataset,
						  maintainAspectRatio: false,
						  grid: {
							//height: heig[0]*0.7,
							top: 20
						  }
					}; 	

		return (
			<td className="sectionContent" colSpan={reference.length} >
				<ReactEcharts 
					//ref={thisRef}
					//onChartReady={e=>console.log('CoLChart onChartReady',e)}
					style={{height: tableSize.height*7-10, width: tableSize.width-20}}
					opts={{renderer: 'svg'}}  
					option={option} />
			</td>
		);
	}

	const TempChart = ({names, temps, haveSize, tableSize}) => {
		const redred = useRef(false);
		const thisRef  = useRef();

		  const dataset = temps.map(data => (
			  {
			  "name": data['key'],
			  symbolSize: 10,
			  type: 'line',
			  smooth: true,
			  emphasis: {
				focus: 'series'
			  },
			  endLabel: {
				show: true,
				formatter: '{a}',
				distance: 10
			  },
			  lineStyle: {
				width: 2
			  },
			  "data": data['value']
			  }
		  ));

		const option = {
		  tooltip: {
			trigger: 'item'
		  },
		  grid: {
			left: 10,
			right: 10,
			bottom: 10,
			containLabel: true
		  },
		  xAxis: {
			type: 'category',
			splitLine: {
			  show: true
			},
			axisLabel: {
			  margin: 2,
			  fontSize: 12
			},
			boundaryGap: false,
			data: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
		  },
		  yAxis: {
			type: 'value',
			axisLabel: {
			  margin: 2,
			  fontSize: 12,
			  formatter: '{value}°C'
			},

		  },
		  series: dataset,
		  width: '80%'
		};

		return (
			<td className="sectionContent" colSpan={names.length} >
				<ReactEcharts
				style={{height: tableSize.height*5-10, width: tableSize.width-20}}
				//opts={{renderer: 'svg'}}  
				option={option} />
			</td>
		);
	}
	
		const TableElement = ({elems, cityParam, cityProps, tableSize}) => {

			const MergedElement = ({elems, cityParam, cityProps}) => {
				const [haveSize, setHaveSize] = useState(false);

				function resizeChart(e) {
					if (!e) return;
					//e.style = {height: "50px", width: "50px"};
					console.log('MergedElement resizeChart',e.style);
					setHaveSize(e);
				}
				return(
				<>
				{{
						'Temperatures': <TempChart tableSize={tableSize} haveSize={haveSize} names={elems} temps={elems.map(city => ({key: city,value: cityProps[city][cityParam]}))}/>,
						'Cost of Living': <CoLChart tableSize={tableSize} reference={elems.map(city => ({key: city,value: cityProps[city][cityParam]}))} />
				}[cityParam]}
				</>
				);				
			}

			const SeparateElement = ({elems, cityParam, cityProps, city}) => {
				return (
				<>
				{elems.map(city => 
					<td className="sectionContent" key={city}>
						{	cityParam != "url" ? cityProps[city][cityParam] : 
							<iframe className="iFrame" src={cityProps[cityParam]}></iframe>
						}
					</td>
				)}
				</>
				);
			}
			
			return(
			<>
			{['Temperatures', 'Cost of Living'].includes(cityParam) ? 
				<MergedElement elems={elems} cityProps={cityProps} cityParam={cityParam} /> :
				<SeparateElement elems={elems} cityProps={cityProps} cityParam={cityParam} />
			}
			</>
			);
		}


		const Contnet = () => {
			const [responseProps,setResponseProps] = useState();
			const tableSize = useRef();
			const [heig,setHeig] = useState({height: 50, width: 100});

			useEffect(() => {
				const urlParams = listElements.current.map(city => {return "&city[]="+city}).join('');
				fetch(`https://safe2change.com/cityParams.php?`+urlParams) //+listElements.current.city
				.then(response => response.json())
				.then(response => {
					console.log('compare city params',response, listElements.current,urlParams);
					setResponseProps(response);
				})
				.catch((e) => {
					console.error(`An error occurred: ${e}`)
				});
			},[]);
			
			function handleResize() {
				if (tableSize.current) {
					console.log('useLayoutEffect',tableSize.current.offsetHeight, tableSize.current.offsetWidth);
					const startNode = isMobile ? 0 : 1;
					const chartWidth = [...tableSize.current.childNodes].filter((_,i) => i>= startNode).reduce((a,v) =>  a = a + v.offsetWidth, 0 );
					setHeig({height: tableSize.current.offsetHeight, width: chartWidth});
				}
			}

			useLayoutEffect(handleResize,[]);

			useEffect(() => {
				window.addEventListener('resize', handleResize);

			},[]);

			return (
				<Table borderless hover responsive size="sm">
					<thead className="">
						<tr className="" ref={tableSize}>
							{!isMobile && <th style={{color: 'transparent'}}>-</th>}
							{listElements.current.map(city => 
								<th key={"CompareCity"+city} className="sectionTitle" style={{textAlign: 'center'}}>
									{city}
								</th>
							)}
						</tr>	
					</thead>
					<tbody className="">
					{
						responseProps && heig && Object.keys(responseProps[listElements.current[0]]).map((key,i) =>  
						<>
							{isMobile &&
								<>
									<tr><td colSpan={listElements.current.length} className="sectionTitle" key={"citypropsName"+i}>{key != "url" ? key : "Wikipedia"}</td></tr>
									<tr><td colSpan={listElements.current.length} className="sectionDivider"></td></tr>
								</>
							}
							<tr className="paramEntries" key={"cityprops"+i}>
								{!isMobile && <td className="sectionTitle" key={"citypropsName"+i}>{key != "url" ? key : "Wikipedia"}</td>}
								<TableElement tableSize={heig} elems={listElements.current} cityProps={responseProps} cityParam={key} />
							</tr>
						</>
							)
					}
					</tbody>
				</Table>
				);
		}

		return (
		  <Modal 
			  show={showCompareModal} 
			  fullscreen={'md-down'} 
			  onHide={() => setShowCompareModal(false)}
			  size="lg"
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  style={{maxHeight: '100%'}}
			  contentClassName="modalContent"
			  dialogClassName="modalDialog"
			  >
			  <Modal.Header closeButton>

			  </Modal.Header>
			  <Modal.Body>
					<Contnet />
			  </Modal.Body>
			  <Modal.Footer></Modal.Footer>
		  </Modal>
		);
	});

function activatePage(activePage) {
	map.current.setPaintProperty('unclustered-point', 'circle-opacity', 1)
				.setPaintProperty('unclustered-point', 'circle-radius', 5)
				.setPaintProperty('clusters', 'circle-opacity', 1)
				.setPaintProperty('clusters', 'circle-stroke-width', 1)
				.setPaintProperty('clusters', 'circle-radius', (['step',['get', 'point_count'],20,100,30,750,40]))
				.setPaintProperty('globalClusters', 'circle-opacity', 1)
				.setPaintProperty('globalClusters', 'circle-stroke-width', 1)
				.setPaintProperty('globalClusters', 'circle-radius', (['step',['get', 'count'],20,100,30,750,40]))
	.setLayoutProperty('globalClusters-count', 'text-size', 12)
				.setLayoutProperty('cluster-count', 'text-size', 12);
}

function deactivatePage(activePage) {
		map.current.setPaintProperty('unclustered-point', 'circle-opacity', 0.5)
	.setPaintProperty('unclustered-point', 'circle-radius', 4)
	.setPaintProperty('clusters', 'circle-opacity', 0.5)
	.setPaintProperty('clusters', 'circle-stroke-width', 0)
	.setPaintProperty('clusters', 'circle-radius', 10)
	.setPaintProperty('globalClusters', 'circle-opacity', 0.5)
	.setPaintProperty('globalClusters', 'circle-stroke-width', 0)
	.setPaintProperty('globalClusters', 'circle-radius', 10)
	.setLayoutProperty('globalClusters-count', 'text-size', 5)
	.setLayoutProperty('cluster-count', 'text-size', 5);	
}




useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10'
    });
	const coordinates = isMobile ? {lng: -22.889800675461515, lat: 25.381496262723246} 
		: {lng: 12.547295294184778, lat: -11.222338643581452}   ;// window.innerHeight < 768 ? {lng: -82.3763544960733, lat: 30.107329893773425} : isMobile ? {lng: -22.611174581037517, lat: 19.601591315804598} : {lng: 8.443366466896748, lat: 29.156767465177197};

		const center = map.current.project(coordinates);
		//const yCorrected = center.y;
		//const xCorrected = center.x;
		const yCorrected = window.innerHeight*0.3 ;
		const xCorrected = window.innerWidth*0.4;
		const correctedCenter = map.current.unproject(window.screen.orientation.type.includes('landscape')&&isMobile ? {x: xCorrected, y: center.y } : {x: center.x, y: yCorrected });
		const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: xCorrected} : {bottom: yCorrected };


	var zoomLevel = isMobile ? 0.66 : 1.93;

	map.current.flyTo({
	  animate: false,
	  zoom: zoomLevel,
	  padding: padding,
	  tolerance: 1,
	  center: coordinates //{lng: -82.85262744690793, lat: 41.59610735983085}
	});

	
	map.current.addControl(mapNav.current = new mapboxgl.NavigationControl());
	prevZoom.current = zoomLevel;

},[]);


useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.once('load', () => {
	console.log('Map loaded',preferenceDetails.current);
	//setMapLoaded(true);
	preferenceDetails.current.destination && map.current.flyTo({
	  animate: true,
	  zoom: preferenceDetails.current.destination[0].value.zoom,
	  tolerance: 1,
	  center: [preferenceDetails.current.destination[0].value.lon,preferenceDetails.current.destination[0].value.lat]
	});
		/*
		map.current.addSource(ciryConturSources[0][0], {"type": "image",
		"url": ciryConturSources[0][1],
			 "coordinates": [
				[ 18.939119, 47.607043], //Budapest
				[ 19.307452, 47.607043], //Budapest
				[ 19.307452, 47.385677], //Budapest
				[ 18.939119, 47.385677] //Budapest
				//[ 13.18181, 52.6539075],	 //Berlin
				//[ 13.618536, 52.6539075],	 //Berlin
				//[ 13.618536, 52.39307],		 //Berlin
				//[ 13.18181, 52.39307]		 //Berlin
			 ]}); //Berlin: 52.35397 52.6539075 13.090932 13.75231, Budapest: 47.38185 47.607043 18.939119 19.307452
		if (!map.current.getLayer("Budapest") ) {

			map.current.addLayer({
			  "id": "Budapest",
			  "source": ciryConturSources[0][0],
			  "type": "raster",
			  "paint": {"raster-opacity": 0.5}
			});
		}
 47.607043,18.939119,47.385677,19.307452

		map.current.addSource(ciryConturSources[1][0], {"type": "image",
		"url": ciryConturSources[1][1],
			 "coordinates": [
				[ 2.2247219, 48.90213], //48.815607 48.90213 2.2247219 2.4683117
				[ 2.4683117, 48.90213], //Paris
				[ 2.4683117, 48.815607], //Paris
				[ 2.2247219, 48.815607] //Paris

			 ]}); //Berlin: 52.35397 52.6539075 13.090932 13.75231, Budapest: 47.38185 47.607043 18.939119 19.307452
		if (!map.current.getLayer("Paris") ) {

			map.current.addLayer({
			  "id": "Paris",
			  "source": ciryConturSources[1][0],
			  "type": "raster",
			  "paint": {"raster-opacity": 0.5}
			});
		}


		map.current.addSource(ciryConturSources[2][0], {"type": "image",
		"url": ciryConturSources[2][1],
			 "coordinates": [
				[ -74.2409, 40.9118], //48.815607 48.90213 2.2247219 2.4683117
				[ -73.7058, 40.9118], //Paris
				[ -73.7058, 40.5088], //Paris
				[ -74.2409, 40.5088] //Paris

			 ]}); //Berlin: 52.35397 52.6539075 13.090932 13.75231, Budapest: 47.38185 47.607043 18.939119 19.307452
		if (!map.current.getLayer("NewYork") ) {

			map.current.addLayer({
			  "id": "NewYork", //40.5088 40.9118 -74.2409 -73.7058
			  "source": ciryConturSources[2][0],
			  "type": "raster",
			  "paint": {"raster-opacity": 0.5}
			});
		}


		map.current.addSource(ciryConturSources[3][0], {"type": "image",
		"url": ciryConturSources[3][1],
			 "coordinates": [
				[ 2.05308, 41.465254], //41.3208897 41.465254 2.05308 2.225316
				[ 2.225316, 41.465254], //Paris
				[ 2.225316, 41.3208897], //Paris
				[ 2.05308, 41.3208897] //Paris

			 ]}); 
		if (!map.current.getLayer("Barcelona") ) {

			map.current.addLayer({
			  "id": "Barcelona", //41.3208897 41.465254 2.05308 2.225316
			  "source": ciryConturSources[3][0],
			  "type": "raster",
			  "paint": {"raster-opacity": 0.5}
			});
		}


		map.current.addSource(ciryConturSources[4][0], {"type": "image",
		"url": ciryConturSources[4][1],
			 "coordinates": [
				[ -0.351414, 51.672094], 
				[ 0.146298, 51.672094], 
				[ 0.146298, 51.384998], 
				[ -0.351414, 51.384998] 

			 ]}); 
		if (!map.current.getLayer("London") ) {

			map.current.addLayer({
			  "id": "London", //51.384998 51.672094 -0.351414 0.146298
			  "source": ciryConturSources[4][0],
			  "type": "raster",
			  "paint": {"raster-opacity": 0.5}
			});
		}  */

				map.current.addSource('places', {
					'type': 'geojson',
					'data': {
						'type': 'FeatureCollection',
						'features': [],
					},
					'cluster': true,
					'clusterMaxZoom': 14, // Max zoom to cluster points on
					'clusterRadius': 25 
				});


				map.current.addSource('hiddenPlaces', {
					'type': 'geojson',
					'data': {
						'type': 'FeatureCollection',
						'features': [],
					}
				});

				map.current.addLayer({
					id: 'hidden-points',
					type: 'circle',
					source: 'hiddenPlaces',
					paint: {
						'circle-radius': 1,
						'circle-opacity': 0
					}
				});		
				


			
				// Add a layer showing the places.
				map.current.addLayer({
					id: 'clusters',
					type: 'circle',
					source: 'places',
					filter: ['has', 'point_count'],
					//minzoom: 8,
					paint: {
						// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
						// with three steps to implement three types of circles:
						//   * Blue, 20px circles when point count is less than 100
						//   * Yellow, 30px circles when point count is between 100 and 750
						//   * Pink, 40px circles when point count is greater than or equal to 750
						'circle-stroke-color': '#4264fb',
						'circle-stroke-width': 1,
						'circle-color': [
							'step',
							['get', 'point_count'],
							'#cccccc',
							100,
							'#bbbbbb',
							750,
							'#aaaaaa'
						],
						'circle-radius': [
							'step',
							['get', 'point_count'],
							20,
							100,
							30,
							750,
							40
						]
					}
				});


				map.current.addLayer({
					id: 'cluster-count',
					type: 'symbol',
					source: 'places',
					//minzoom: 8,
					filter: ['has', 'point_count'],
					layout: {
						'text-field': ['get', 'point_count_abbreviated'],
						'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
						'text-size': 12
					}
				});

				map.current.addLayer({
					id: 'unclustered-point',
					type: 'circle',
					source: 'places',
					//minzoom: 8,
					//offset: [10, 10],
					filter: ['!', ['has', 'point_count']],
					paint: {
						'circle-color': '#4264fb',
						'circle-radius': [
									'interpolate',
									['linear'],
									['zoom'],
									8,
									6,
									15, 
									8
									],
						'circle-stroke-width': 2,
						'circle-stroke-color': '#fff'
					}
				});

				/*map.current.addLayer({
					'id': 'unclustered-point-labels',
					'type': 'symbol',
					'source': 'places',
					'filter': ['!', ['has', 'point_count']],
					'layout': {
						'text-field': ['number-format',['get', 'price'],{ 'locale': 'en-US', 
																			'currency': ['get', 'currency'], 
																			'min-fraction-digits': 1, 
																			'max-fraction-digits': 2,
																			'font-scale': 0.8,
																			'text-font': [
																			'literal',
																			['DIN Offc Pro Italic', 'Arial Unicode MS Regular']
																			]
																			}],
						'text-variable-anchor': ['top'],
						'text-radial-offset': 0.5,
						'text-justify': 'auto',
					},
					'paint': {
						'text-color': '#000',
						'text-halo-color': '#fff',
						'text-halo-width': 2
					},
				});*/
				


        map.current.addSource('globalPlaces', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [],
            }
        });

	
        // Add a layer showing the places.
        map.current.addLayer({
            id: 'globalClusters',
            type: 'circle',
            source: 'globalPlaces',
			//maxzoom: 8,
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-stroke-color': '#4264fb',
                'circle-stroke-width': 1,
				'circle-color': [
                    'step',
                    ['get', 'count'],
                    '#cccccc',
                    100,
                    '#bbbbbb',
                    750,
                    '#aaaaaa'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        });


        map.current.addLayer({
            id: 'globalClusters-count',
            type: 'symbol',
            source: 'globalPlaces',
			//maxzoom: 8,
            layout: {
                'text-field': ['get', 'count'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });

		
		initLoad();
		setTriggerRerendering(c=>!c);
		
		map.current.off('load');

		
    });
  },[]);

  
  
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('mouseup', 'clusters', (e) => {
			if (e.originalEvent) {
				e.originalEvent.stopPropagation();
				e.originalEvent.stopImmediatePropagation();
				e.preventDefault();
				e.originalEvent.preventDefault();
			}

           const features = map.current.queryRenderedFeatures(e.point, {layers: ['clusters']});

            const clusterId = features[0].properties.cluster_id;
			console.log('click clusters',refSwiper.current.parentNode.getBoundingClientRect());
			const yCorrected = window.innerHeight*0.3 ;
			const xCorrected = window.innerWidth*0.4;
			const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: xCorrected} : {bottom: yCorrected };
            map.current.getSource('places').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.current.easeTo({
                        center: features[0].geometry.coordinates,
						padding: padding,
                        zoom: zoom
                    },features[0].geometry);
                }
            );
		map.current.off('mouseup', 'clusters');
        });

  },[]);
  
  
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('mousedown', 'globalClusters', (e) => {
			globalClustersMouseDown.current = e.originalEvent.timeStamp;
			timer = setTimeout(() => {
				cityPropsPopup.current.openPopup(e);
			}, 1000);
		map.current.off('mousedown', 'globalClusters');
		return () => clearTimeout(timer);
        });

  },[]);  

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('touchstart', 'globalClusters', (e) => {
			globalClustersMouseDown.current = e.originalEvent.timeStamp;
			timer = setTimeout(() => {
				cityPropsPopup.current.openPopup(e);
			}, 1000);
		map.current.off('touchstart', 'globalClusters');
		return () => clearTimeout(timer);
        });

  },[]);  
  
  function clickRelease(e) {
			clearTimeout(timer);			
			if (e.originalEvent.timeStamp - globalClustersMouseDown.current < 1000) {
				cityPropsPopup.current.closePopup(e);
				const features = map.current.queryRenderedFeatures(e.point, {
					layers: ['globalClusters']
				});
				const clusterId = features[0].properties.cluster_id;
				console.log('click globalClusters',features[0].properties.city);
				const yCorrected = window.innerHeight*0.3 ;
				const xCorrected = window.innerWidth*0.4;
				const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: xCorrected} : {bottom: yCorrected };
				loadData({city: features[0].properties.city});
			}	  
  }
  
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('touchend', 'globalClusters', (e) => {
			if (e.originalEvent) {
				e.preventDefault();
				e.originalEvent.stopPropagation();
				e.originalEvent.stopImmediatePropagation();
			}
			clickRelease(e);

           /*
            map.current.easeTo({
                        center: features[0].geometry.coordinates,
						padding: padding,
                        zoom: 8.1
                    },features[0].geometry);*/
			map.current.off('touchend', 'globalClusters');

        });

  },[]);  
  
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('mouseup', 'globalClusters', (e) => {
			if (e.originalEvent) {
				e.preventDefault();
				e.originalEvent.stopPropagation();
				e.originalEvent.stopImmediatePropagation();
			}
			clickRelease(e);

           /*
            map.current.easeTo({
                        center: features[0].geometry.coordinates,
						padding: padding,
                        zoom: 8.1
                    },features[0].geometry);*/
			map.current.off('mouseup', 'globalClusters');

        });

  },[]);  
  
	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
		map.current.on('click', (e) => {
			// preventDefault, stopPropagation does not work
			const layerNames = ['hiddenPlaces', 'globalPlaces', 'places'];
			if (map.current.queryRenderedFeatures(e.point).filter(feature => layerNames.includes(feature.source)).length<1) spiderRef.current.minify(e);
			map.current.off('click');
		});
	},[]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
       map.current.on('zoomstart', (e) => {

			removePopups();
			//popupCard && setPopupCard(false);
		map.current.off('zoomstart');
        });

  },[]);
  
  useEffect(() => {
		if (selected.length >1) {	   

			//console.log("https://api.mapbox.com/directions-matrix/v1/mapbox/driving-traffic/"+selected[0].geometry.coordinates[0]+","+selected[0].geometry.coordinates[1]+";"+selected[1].geometry.coordinates[0]+","+selected[1].geometry.coordinates[1]+"?access_token="+mapboxgl.accessToken);
			fetch("https://api.mapbox.com/directions-matrix/v1/mapbox/driving-traffic/"+selected[0].geometry.coordinates[0]+","+selected[0].geometry.coordinates[1]+";"+selected[1].geometry.coordinates[0]+","+selected[1].geometry.coordinates[1]+"?access_token="+mapboxgl.accessToken, {
			   method: "GET",
			})
		  .then(response => response.json())
		  .then(response => {
			  const matrix = response.body;
			  console.log('matrix is here',response);
		  });
		}
  
  },[selected]);

function refreshSwiperCards (recievedFeatures) {

		const theList = {2:rentalsGEOJson.current,
						 3: jobsGEOJson.current
						}[refSwiper.current.swiper.activeIndex];
		const visibleFeatures = recievedFeatures ? recievedFeatures : map.current.queryRenderedFeatures([[0,0],[window.innerWidth,refSwiper.current.getBoundingClientRect().top-37]],{ layers: ['hidden-points'] });
			const allIDs = visibleFeatures.map(elem => elem.properties.id);
			const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
			const uniquePoints = visibleFeatures.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos); 

		
		console.log('refreshSwiperCards',uniquePoints.length,preHiddenFeaturesLen.current);
		if (uniquePoints.length==0 && preHiddenFeaturesLen.current != 0) {
				if (refSwiper.current.swiper.activeIndex == 2) 
					{updateRentalCardsArray(initRentalsGEOJson.current)}
				else 
					{updateJobCardsArray(initJobsGEOJson.current)}

			preHiddenFeaturesLen.current = 0;
		} else {
			if (uniquePoints.length !== preHiddenFeaturesLen.current) { // check if there's any change
				//rentalsGEOJson.current = tempArra  ;
				if (refSwiper.current.swiper.activeIndex == 2) 
					{updateRentalCardsArray(uniquePoints)}
				else 
					{updateJobCardsArray(uniquePoints)}
				visibleCard.current=0;
			preHiddenFeaturesLen.current = uniquePoints.length;

			}
		}
}



function AddTooptips(feature, i) {
	const pointPos = map.current.project(feature.geometry.coordinates);
	console.log('AddTooptips coordinate',pointPos.x,pointPos.y);

  return (
				<div key={i} style={{position: 'absolute',top: `${pointPos.y}px`, left: `${pointPos.x}px`}}>
				  <TooltipBs placement="bottom" className="in" id="tooltip-bottom" style={{display: 'contents'}}>
					  {feature.properties.price}
				  </TooltipBs>
		</div>
  );
}


function removePopups() {
	popups.current.map((pps) => pps.remove());
	//popup.remove();
	preUnclusteredFeaturesLen.current = [];
	cityPropsPopup.current.closePopup();
}


  /*useEffect(() => {
       map.current.on('render','hidden-points', (e) => {
			console.log('hidden-points rendered',e.features.length);
			if (map.current.isZooming() || map.current.isMoving()) return;
			if (e.features.length !== preHiddenFeaturesLen.current) {
				const currentZoom = map.current.getZoom();
				//console.log('hidden-points rendered',map.current.queryRenderedFeatures({ layers: ['hidden-points'] }));
				//if ((Math.abs(currentZoom - prevZoom.current)>0.5 ) && currentZoom > 8) {
				if (e.features.length !== preHiddenFeaturesLen.current) {  // in case of dataload, zoom, drag
					refreshSwiperCards(e.features);
					prevZoom.current = currentZoom;
					//addPriceTags(e.features);
					preHiddenFeaturesLen.current = e.features.length;
					}
			}
		map.current.off('render','hidden-points');
	});
  },[]);*/



  useEffect(() => {
       map.current.on('render','unclustered-point', (e) => {
		   
			//console.log('unclustered-point rendered',e.features,uniquePoints);
			
			if (!(map.current.isZooming() || map.current.isMoving())) {
				if (e.features.length !== preUnclusteredFeaturesLen.current.length) {
					//console.log('render unclustered-point',rentalModalRef);

					addPriceTags(e.features);
					preUnclusteredFeaturesLen.current = e.features;
				}
			}
		map.current.off('render','unclustered-point');
	});
  },[]);

function openPopup(e,feature,ppup) {
	console.log('openPopup',rentalModalRef);
	e.stopPropagation();
	if (feature.properties.id) {
		const geoJSONEntry = initRentalsGEOJson.current.filter((prop) => feature.properties.id==prop.properties.id);
		console.log('openPopup',geoJSONEntry,feature.properties.id);
		const content = createPopupContent(feature.properties);
		const coordinates = feature.geometry.coordinates.slice();
		popup.setLngLat(coordinates).setHTML(content).addTo(map.current);
		ppup.getElement().removeEventListener('click',() => openPopup());
		popup.getElement().addEventListener('click', (e) => rentalModalRef.current.openModal([geoJSONEntry[0].properties.id],geoJSONEntry[0].properties.price ? "Rentals": "Jobs"));

	}
}

    useEffect(() => {
       if (!popup) return;
	   popup.on('click', (e) => {
			
			console.log('useEffect popup.on click',e);
        });
		return () => popup.off('click');

	},[]);

function createPopupContent(props) {

	return(


'<div class="row " style="width: 100%; margin-left: 0;">'
	+'<div class="popupImgContainer"><button type="button" class="btn btn-outline-primary btn-sm" style="background-color: rgba(255,255,255,0.7);box-shadow: 5px 5px 15px black;">More</button></div>'
	+'<div class="popupImg">'
		+'<img class="card-img-top" fluid="true" src="'+correctImgAddress(props.image)+'">'
	+'</div>'
	+'<div class="popuptext" style="">'
			+'<div class="g-2 row" style="margin: 0px;">'
				+'<div class="col-4" style="text-align: left; font-size: 0.7rem; font-weight: normal; margin: 0px;">Price</div>'
				+'<div class="col-8" style="text-align: right; font-size: 0.9rem; font-weight: bold; margin: 0px;">'+props.price+'<span style="font-size: 0.54rem;">USD</span></div>'
			+'</div>'
			+'<div class="g-2 row" style="margin: 0px;">'
				+'<div class="col-11" style="text-align: left; font-size: 0.7rem; font-weight: normal; margin: 0px;">Bedrooms</div>'
				+'<div class="col-1" style="text-align: right; font-size: 0.7rem; font-weight: bold; margin: 0px;">'+props.bed+'<span style="font-size: 0.5rem;"></span></div>'
			+'</div>'
			+'<div class="g-2 row" style="margin: 0px;">'
				+'<div class="col-11" style="text-align: left; font-size: 0.7rem; font-weight: normal; margin: 0px;">Bathrooms</div>'
			+'<div class="col-1" style="text-align: right; font-size: 0.7rem; font-weight: bold; margin: 0px;">'+props.bed+'<span style="font-size: 0.5rem;"></span></div>'
		+'</div>'
	+'</div>'
+'</div>'

	);
}

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Popover right</Popover.Header>
    <Popover.Body>
      And here's some <strong>amazing</strong> content. It's very engaging.
      right?
    </Popover.Body>
  </Popover>
);

							const PopupPopover = ({ children, lngLat, onClose }) => {
								const popupRef = useRef();
								  useEffect(() => {
									const popup = new mapboxgl.Popup({})
									  .setLngLat(lngLat)
									  .setDOMContent(popupRef.current)
									  .addTo(map);

									popup.on("close", onClose);

									return popup.remove;
								  }, [children, lngLat]);
								return (
								  <OverlayTrigger ref={popupRef} trigger="click" placement="right" overlay={popover}>
									  {children}
								  </OverlayTrigger>								
								);
							}

function addPriceTags(params) {
	
	const features = params ? params : map.current.queryRenderedFeatures([[0,0],[window.innerWidth,refSwiper.current.getBoundingClientRect().top-37]],{ layers: ['unclustered-point'] });
	const allIDs = features.map(elem => elem.properties.id);
	const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
	const uniquePoints = features.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos); 

	//console.log('addPriceTags',features, uniquePoints);
	if (preUnclusteredFeaturesLen.current.length == uniquePoints.length) return;
	removePopups();
	if (uniquePoints) {
		const rjs = {2:'Rentals',
						 3: 'Jobs',
						 4: 'Schools'
						}[refSwiper.current.swiper.activeIndex];
		const likeList = {
			'Rentals': rentalPlan.current,
			'Jobs': jobPlan.current
		}[rjs];

						uniquePoints.map((feature,i) => {
							const likedFlag = likeList && likeList.includes(feature.properties.id) ? '<svg  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="color: red;padding: 2px;"><path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg>':'';
							const valueToShow = {2:feature.properties.price,
												 3: feature.properties.salary,
												 4: feature.properties.fee
						}[refSwiper.current.swiper.activeIndex];
						
								const placement = {
									0:'top-left',
									1:'top-right',
									3:'bottom-left',
									4:'bottom-right',
									5: 'top' , 
									6: 'bottom' , 
									7: 'left' , 
									8: 'right'
								}[Math.floor(Math.random() * 8)];
						
							const content = '<div id='+feature.properties.id+' class="popupprice">'+Number(valueToShow).toLocaleString('en')+likedFlag
							+'</div>';
							//toolTipList[i] = feature && AddTooptips(feature, i);

							popups.current[i] = new mapboxgl.Popup({
								closeButton: false,
								anchor: placement,
								className: 'popupContent',
								id: feature.properties.id
							})
							.setLngLat(feature.geometry.coordinates)
							.setHTML(content)
							.addTo(map.current);
							
							popups.current[i].getElement().addEventListener('click',(e) => openPopup(e,feature, popups.current[i],rentalModal.current));
						});
						preUnclusteredFeaturesLen.current = uniquePoints;

					} 
	}


	function setLayerProperty(globalOff) {
		map.current.setLayoutProperty('globalClusters', 'visibility', globalOff ? 'none' : 'visible')
					.setLayoutProperty('globalClusters-count', 'text-size', globalOff ? 1 : 12)
					.setLayoutProperty('clusters', 'visibility', !globalOff ? 'none' : 'visible')
					.setLayoutProperty('cluster-count', 'visibility', !globalOff ? 'none' : 'visible')
					.setLayoutProperty('unclustered-point', 'visibility', !globalOff ? 'none' : 'visible');
	}

	function switchGlobalLocalLayers(globalOff, city) {
			const renderedGlobalClustersCount = map.current.queryRenderedFeatures({ layers: ['globalClusters-count'] });
			if (renderedGlobalClustersCount.length == 1 && map.current.getLayer('globalClusters-count') && map.current.getLayoutProperty('globalClusters-count','text-size') == 12) {
				setLayerProperty(true);
				loadData(renderedGlobalClustersCount[0].properties);
			} else if (renderedGlobalClustersCount.length > 1 && map.current.getLayoutProperty('globalClusters-count','text-size') == 1) {
				setLayerProperty(false);
				loadingInitData.current = true;
				initLoad();
			} 
	}

	useEffect(() => {
       map.current.on('moveend', (e) => {
		//console.log('moveend',map.current.getCenter(), map.current.getZoom(),e);
		if (e.eventParam && e.eventParam[0] == "handleLocator") {
			locatorMarker.setLngLat({lon: e.eventParam[1].lng, lat: e.eventParam[1].lat})
				.addTo(map.current);
		}
		
		switchGlobalLocalLayers();

		//addPriceTags(); <AiOutlineLoading3Quarters />
		const timer = setTimeout(() => {
		  locatorMarker.remove();
		}, 4000);
			});
			return () => map.current.off('moveend');

	},[]);



  useEffect(() => {
       map.current.on('zoomend', (e) => {
			  console.log('zoomend',e);
			const currentZoom = map.current.getZoom();
			const bounds = map.current.getBounds();
			const zoomIn = prevZoom.current < currentZoom ? true : false;

		/*
			if (e.coordinates) {
				const center = map.current.project(map.current.getCenter());
				const yCorrected = center.y + window.innerHeight*0.2 ;
				const xCorrected = center.x - window.innerWidth*0.15 ;
				  console.log('zoomstart',center,yCorrected,xCorrected);
				const correctedCenter = map.current.unproject(window.screen.orientation.type.includes('landscape')&&isMobile ? {x: xCorrected, y: center.y } : {x: center.x, y: yCorrected } );
				map.current.panTo(
					 correctedCenter
				);	
			}
		*/
			switchGlobalLocalLayers();

			if (e.originalEvent && !e.originalEvent.type == "wheel" ) { // zoom is triggered by other events, not direct user zoom. Eg. click on cluster
				//currentZoom < 8 && loadData();
			} else { // zoom is triggered by direct user zoom event

			const pointsInBound = map.current.getLayer('hidden-points') && map.current.queryRenderedFeatures([[0,0],[window.innerWidth,refSwiper.current.getBoundingClientRect().top-37]],{ layers: ['hidden-points'] });

			const allIDs = pointsInBound.map(elem => elem.properties.id);
			const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
			const uniquePoints = pointsInBound.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos); 


			//const pointsInBound = geoJsonList.filter((rental) => isInBounds(rental.geometry.coordinates, bounds));
			if (uniquePoints.length !== preHiddenFeaturesLen.current) {  // in case of dataload, zoom, drag
				//refreshSwiperCards(uniquePoints);
				preHiddenFeaturesLen.current = uniquePoints.length;
			}
					prevZoom.current = currentZoom;

			}
			map.current.off('zoomend');
        });

  },[]);

function isInBounds(point, bounds) {
	const ne=bounds.getNorthEast();
	const sw=bounds.getSouthWest();
	
	return isBetween(point[0],[ne.lng,sw.lng]) && isBetween(point[1],[ne.lat,sw.lat]);
}


function updateRentalCardsArray(newData) {
	console.log('updateRentalCardsArray',newData.length);
	positionMarker.remove();
	rentalsGEOJson.current = newData;
	visibleCard.current=0;
	console.log('triggering re-render?',skipNextRender.current);
	//skipNextRender.current ? skipNextRender.current = false : 
	//setTriggerRerendering(c=>!c);
} 

function updateJobCardsArray(newData) {
	console.log('updateJobCardsArray',newData.length);
	positionMarker.remove();
	jobsGEOJson.current = newData;
	console.log('triggering re-render?',skipNextRender.current);
	//skipNextRender.current ? skipNextRender.current = false : 
	//setTriggerRerendering(c=>!c);

	  visibleCard.current=0;

} 

  useEffect(() => {
       map.current.on('dragstart', (e) => {
		console.log('dragstart',e);
		removePopups();
		

        });
		return () => map.current.off('dragstart');

  },[]);
  
 
    useEffect(() => {
    if (!map.current) return; // wait for map to initialize
       map.current.on('idle', (e) => {
			const pointsInBound = map.current.getLayer('hidden-points') && map.current.queryRenderedFeatures([[0,0],[window.innerWidth,refSwiper.current.getBoundingClientRect().top-37]],{ layers: ['hidden-points'] });
			const allIDs = pointsInBound.map(elem => elem.properties.id);
			const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
			const uniquePoints = pointsInBound.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos);


			//const pointsInBound = geoJsonList.filter((rental) => isInBounds(rental.geometry.coordinates, bounds));
			if (uniquePoints.length !== preHiddenFeaturesLen.current) {  // in case of dataload, zoom, drag
				map.current.getSource('places')?.setData(featureCollection(uniquePoints));
				//refreshSwiperCards(uniquePoints);
				preHiddenFeaturesLen.current = uniquePoints.length;
				//Array(rentalcardBodyRef.current.childNodes[].childNodes).map(child => console.log('rentalcardBodyRef',child,child.id));
			}
			
        });
		return () => map.current.off('idle');

  },[]);
  
/*    useEffect(() => {
       map.current.on('sourcedata', (e) => {

		if (e.sourceId == "places" && e.isSourceLoaded && e.sourceCacheId) {
			console.log('map.current.on sourcedata',e);
			const pointsInBound = map.current.queryRenderedFeatures({ layers: ['hidden-points'] });
			const allIDs = pointsInBound.map(elem => elem.properties.id);
			const uniqueIDs = allIDs.filter((elem, pos) => allIDs.indexOf(elem) == pos); 
			const uniquePoints = pointsInBound.filter((elem, pos) => uniqueIDs.indexOf(elem.properties.id) == pos); 


			//setTriggerRerender(c=>!c)
			//addPriceTags(pointsInBound);
		}

        });
		return () => map.current.off('sourcedata');

  },[]);
*/  

  useEffect(() => {
       map.current.on('error', (e) => {
		console.log('error',e);
        });
		return () => map.current.off('error');

  },[]);


  useEffect(() => {
       map.current.on('dragend', (e) => {
		console.log('dragend',rentalModal);
		addPriceTags();
        });
		return () => map.current.off('dragend');

  },[]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

        map.current.on('mouseenter', ['clusters','globalClusters'], (e) => {
			if (e.originalEvent) {
				e.originalEvent.stopPropagation();
				e.originalEvent.stopImmediatePropagation();
			}
            map.current.getCanvas().style.cursor = 'pointer';
        });
		return () => map.current.off('mouseenter', ['clusters','globalClusters']);

  },[]);


  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
        map.current.on('mouseleave', ['clusters','globalClusters'], (e) => {
			if (e.originalEvent) {
				e.originalEvent.stopPropagation();
				e.originalEvent.stopImmediatePropagation();
			}
            map.current.getCanvas().style.cursor = '';
        });
		return () => map.current.off('mouseleave', ['clusters','globalClusters']);

  },[]);

  useEffect(() => {
    if (!refSwiper.current) return; // wait for map to initialize
        refSwiper.current.swiper.on('slideChange', (e) => {
			console.log('slideChange',e);
		if (preActIndex.current == e.activeIndex) return;
		preActIndex.current = e.activeIndex;
		removePopups();

		if (!map.current) return; // initialize map only once
		  if (map.current.getLayer('unclustered-point')) { 
			switch (e.activeIndex) {
				case 2: {
					positionMarker.remove();
					map.current.getSource('hiddenPlaces').setData(featureCollection(rentalsGEOJson.current));
					map.current.setFilter('hidden-points',null);
					//map.current.getSource('places').setData(featureCollection(rentalsGEOJson.current));
					swipePageFilter.current && swipePageFilter.current.changeFilters('Rentals');
					console.log('slideChange hiddenPlaces1',map.current.getFilter('hidden-points'));
					return;
				}
				case 3: {
					console.log('slideChange hiddenPlaces0',map.current.getFilter('hidden-points'));

					positionMarker.remove();
					map.current.getSource('hiddenPlaces').setData(featureCollection(jobsGEOJson.current));
					map.current.setFilter('hidden-points',null);
					swipePageFilter.current && swipePageFilter.current.changeFilters('Jobs');
					console.log('slideChange hiddenPlaces1',map.current.getFilter('hidden-points'));
					//map.current.getSource('places').setData(featureCollection(jobsGEOJson.current));
					return;
				}
			}
		  }
		return;		
        });
		return () => refSwiper.current.swiper.off('slideChange');

  },[]);


	  
function loadData(param) {
		const bounds = map.current.getBounds();
		const fetchRentals = param.city ? `https://safe2change.com/FlatPrices.php?rjs=r&city=`+param.city : `https://safe2change.com/FlatPrices.php?rjs=r&bounds=`+bounds;
		console.log('fetchRentals',fetchRentals);
		
		fetch(fetchRentals,{
			mode: 'no-cors',
			credentials: 'include',
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		//fetch(fetchAddress) // add here the zoomIn data to fetch data within bounds
		  .then(response => response.json())
		  .then((usefulData) => {
			console.log('loadData usefulData',usefulData);
			if (usefulData) {
			  usefulData['Rentals'] = usefulData['Rentals'].filter((item) => item.properties.city == param.city);
			  
			usefulData['Rentals'].map((item) => {
				if (!item.properties.image) {item.properties.image="https://photos.renthop.com/p/c/420x300/65269292_ea4a9f320ca10494c23afea79fd417d4.jpg";}
				if (!item.properties.bed) {item.properties.bed=1;}
				if (!item.properties.bath) {item.properties.bath=1;}
				item.properties.pets = !item.properties.size;
				item.properties.garage = item.properties.bed > 1 ? true : false;
				
				if (!item.properties.address) {item.properties.address ="-";}
				item.properties.price = Number(item.properties.price) ;
				item.properties.bed = Number(item.properties.bed) ;
				item.properties.bath = Number(item.properties.bath) ;
				item.properties.size = Number(item.properties.size) ;
				item.properties.imageList = item.properties.imageList.length<2 ? Array.from({ length: 2 }).map((i) => item.properties.image) : item.properties.imageList;



			});
				initRentalsGEOJson.current = usefulData['Rentals'];
				rentalsGEOJson.current = usefulData['Rentals'];
 				if (refSwiper.current.swiper.activeIndex == 2 ) {
						const features = featureCollection(usefulData['Rentals']);
						map.current.getSource('hiddenPlaces').setData(features);
						map.current.getSource('places').setData(features);
						const widthPadding = window.innerWidth * 0.1;
						const heightPadding = window.innerHeight*0.1;
						const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: widthPadding, right: widthPadding, top: heightPadding, bottom: heightPadding} : {bottom: heightPadding, left: widthPadding, right: widthPadding, top: heightPadding};

						map.current.fitBounds(bbox(features.features), {padding: padding, duration: 2000, easing(t) {return t;}});
					}

			usefulData['Jobs'].map((item) => {
				if (!item.properties.image) {item.properties.image="https://photos.renthop.com/p/c/420x300/65269292_ea4a9f320ca10494c23afea79fd417d4.jpg";}
				});


				initJobsGEOJson.current = usefulData['Jobs'];
				jobsGEOJson.current = usefulData['Jobs'];
 				if (refSwiper.current.swiper.activeIndex == 3 ) {
						const features = featureCollection(usefulData['Jobs']);
						map.current.getSource('hiddenPlaces').setData(features);
						map.current.getSource('places').setData(features);
						const widthPadding = window.innerWidth * 0.1;
						const heightPadding = window.innerHeight*0.1;
						const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: widthPadding, right: widthPadding, top: heightPadding, bottom: heightPadding} : {bottom: heightPadding, left: widthPadding, right: widthPadding, top: heightPadding};

						map.current.fitBounds(bbox(features.features), {padding: padding, duration: 2000, easing(t) {return t;}});
					}
			}
		  })
		  .catch((e) => {
			console.error(`An error occurred: ${e}`);
		  });


  }


function handleScroll(e) {

		var theList = {2:rentalsGEOJson,
						 3: jobsGEOJson,
						 4: rentalsGEOJson
						}[refSwiper.current.swiper.activeIndex];

	var prev = visibleCard.current;
	if (isMobile) { 
		(e.target.getBoundingClientRect().top+50 > e.target.childNodes[0].childNodes[visibleCard.current].getBoundingClientRect().bottom) && visibleCard.current++;
		(e.target.getBoundingClientRect().top+50 < e.target.childNodes[0].childNodes[visibleCard.current].getBoundingClientRect().top) && visibleCard.current--;
		if (prev != visibleCard.current) {
			map.current.easeTo({
				center: theList.current[visibleCard.current].geometry.coordinates});
			
			positionMarker.remove();
			positionMarker.setLngLat({lon: theList.current[visibleCard.current].geometry.coordinates[0], lat: theList.current[visibleCard.current].geometry.coordinates[1]})
					.addTo(map.current);
		}
	}
}



function handleOrientationChange(e) {
	console.log('handleOrientationChange',(window.screen.orientation));
	setIsMobile(isBetween( window.innerWidth,[576,0] ) || isBetween( window.innerHeight,[576,0] ) ? true:false);

}

useEffect(() => {
  setCardsPerRow(
	  isMobile || isBetween(window.innerWidth,[576,767]) ? 1 : 
		isBetween(window.innerWidth,[767,900]) || (isBetween( window.innerHeight,[576,768]) && isBetween( window.innerWidth,[576,0] )) ? 2 : 4
		);
	console.log('orientationchange CardsPerRow',	  isMobile || isBetween(window.innerWidth,[576,767]) ? 1 : 
		isBetween(window.innerWidth,[767,900]) || (isBetween( window.innerHeight,[576,768]) && isBetween( window.innerWidth,[576,0] )) ? 2 : 4
		);
},[isMobile]);

useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);

},[]);


  return (
    <>
		<OwnPopup ref={cityPropsPopup} />
		<Medals medals={medals} />
		<CompareCityModal ref={compareCityModal}/>
			{triggerRerendering && <SetNavbar isEmptyRef={isEmptyRef} compareModalRef={compareModalRef}/>}
	
		<div className="MyMap" > 
			<div className="map-container"> 
			  <div ref={mapContainer} style={{top: '0px'}}/>
			</div>	
		</div>			

		<AddSwiperContainer map={map} cityPropsPopup={cityPropsPopup} ref={spiderRef} isMobile={isMobile}>	
			<AddSwiper map={map} ref={refSwiper} preActIndex={preActIndex} rentalsGEOJson={rentalsGEOJson} jobsGEOJson={jobsGEOJson} isMobile={isMobile}>
				<RentalCards type={'Rentals'} rentals={rentalsGEOJson.current}  navs={true} map={map} rentalModal={rentalModalRef} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList} swipePageFilter={swipePageFilter}/>
				<RentalCards type={'Jobs'} rentals={jobsGEOJson.current}  navs={true} map={map} rentalModal={rentalModalRef} isEmptyRef={isEmptyRef} compareModalRef={compareModalRef} rjsLikedList={rjsLikedList} swipePageFilter={swipePageFilter}/>
			</AddSwiper>
		</AddSwiperContainer>
		<RentalModal ref={rentalModalRef} isEmptyRef={isEmptyRef} initRentalsGEOJson={initRentalsGEOJson} />
    </>
  );
}

const AddSwiperContainer = forwardRef(({children, map,preActIndex,cityPropsPopup, isMobile},ref) => {
	const minSwiperRef = useRef(null);

		useImperativeHandle(ref, () => {
			return {
				minify(e) { 
					minifySwiper(e);
					}
			};
		}, []);

	function minifySwiper (e) {
		console.log('minifySwiper',e.target.className, minSwiperRef);
		e.originalEvent ? e.originalEvent.stopPropagation() : e.stopPropagation();
		if (e.target.className && e.target.className.includes('swiper-button')) return;
		const collapse = minSwiperRef.current.className.includes("swiperSmall") ? false :true ;
		minSwiperRef.current.className.includes("swiperSmall") ? minSwiperRef.current.className = "swiperContainer" : minSwiperRef.current.className += " swiperSmall";
		if (!map.current) return;
		const center = map.current.project(map.current.getCenter());
			const yCorrected = collapse ? window.innerHeight*0.1 : (isMobile? window.innerHeight*0.5 : window.innerHeight*0.3);
			const xCorrected = collapse ? window.innerWidth*0.2 : window.innerWidth*0.4;
		const padding = window.screen.orientation.type.includes('landscape')&&isMobile ? {left: xCorrected} : {bottom: yCorrected };
        map.current.easeTo({
            padding: padding
        });	
	}


	return (
		<div className= "swiperContainer" ref={minSwiperRef}>
			{children}
		</div>
	);
});

const AddSwiper = memo(forwardRef(function AddSwiper({children, map,preActIndex, rentalsGEOJson, jobsGEOJson, isMobile},refSwiper) {
  //const verticalCoverflow = {depth: 0,rotate: 0,stretch: 0,scale: 1,};
  //const horizontalCoverflow = {depth: 500,rotate: -33,stretch: 100,scale: 0.7};

	function fadePoints(activePage) {
		console.log('fadePoints',preActIndex.current,activePage);
		if (preActIndex.current == activePage) return;
		preActIndex.current = activePage;
		//removePopups();

		if (!map.current) return; // initialize map only once
		  if (map.current.getLayer('unclustered-point')) { 
			switch (activePage) {
				case 2: {
					//activatePage(activePage);
					map.current.getSource('hiddenPlaces').setData(featureCollection(rentalsGEOJson.current));
					map.current.getSource('places').setData(featureCollection(rentalsGEOJson.current));

					return;
				}
				case 3: {
					//activatePage(activePage);
					map.current.getSource('hiddenPlaces').setData(featureCollection(jobsGEOJson.current));
					map.current.getSource('places').setData(featureCollection(jobsGEOJson.current));

					return;
				}
			}
		  }
		return;
	}

	return(
			<Swiper 
				effect= {"coverflow"}
				//init={false}
				//initialSlide = {0}
				grabCursor= {true}
				loop={true}
				roundLengths={true}
				centeredSlides= {true}
				slidesPerView= {1.2} //
				observer= {true}
				observeParents= {true}
				spaceBetween= {-50}
				ref={refSwiper}
				navigation={!isMobile}
				className="mainSwiper"
				//direction={changedCoverflow }
				coverflowEffect= {{depth: 500, rotate: -33, stretch: 100, scale: 0.7}}
				//breakpoints={{768: changedCoverflow}}	
				modules= {[Navigation,EffectCoverflow, Controller]}
				onSlideChange={(e) => {
					//visibleCard.current = 0;
					//e.activeIndex == 5 && e.slideTo(2, 0, false);
					//fadePoints(e.activeIndex);
					}}
				>
				{children.map((childItem,i) =>
				<SwiperSlide key={"childItem"+i} className="mainSwiperSlide">
					{childItem}
				</SwiperSlide>
				)}
			</Swiper>
	
	);
}));



export default App;
